import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject,Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Utilityservicesplanning,EquipmentMaster } from 'src/app/model';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class UtilityservicesplanningService {

    public pageName = 'Utility Services Planning';
    public dataList = new Subject<Array<Utilityservicesplanning>>();
    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private common: CommonDataService,
        private msgSvc: MsgService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.UtilityservicesplanningApi[apiOption];
    }
    public getEquipments(batch_num:string,step_id:number,param_id:number) {
    
            const dataArray: Array<Utilityservicesplanning> = [];
            const data = new BehaviorSubject<Array<Utilityservicesplanning>>([]);
            const fData = new FormData();
            fData.append('batch_num', batch_num);
            fData.append('step_id', step_id+'');
            fData.append('param_id', param_id+'');
            this.http.post(this.getUrl(ApiActions.GetSingle), fData, { headers: undefined })  .subscribe((response: any) => {
                if (response && response.result) {
                  response.result.UtilityservicesplanningList.map(element => {
                    element.status = element.status == '1' ? true : false;
                    dataArray.push(element);
                  });
                  data.next(dataArray);
                }
              },
                err => {
                    this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
                });
            return data;
      }

    public getDataRecords() {
        const dataArray: Array<Utilityservicesplanning> = [];
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.UtilityservicesplanningList) {
                    response.result.UtilityservicesplanningList.map(element => {
                        dataArray.push(element);
                    });
                }
                this.dataList.next(dataArray);
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
    }
    public getDetailsDataRecords(eqpcode: string) {
        const fData = new FormData();
        fData.append('eqpcode', eqpcode);
        const url = this.config.ipAddress + this.config.equipmentplanningApi[ApiActions.GetSingle];
        const equipmentdetailsList = new Subject<Array<Utilityservicesplanning>>();
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.UtilityservicesplanningList) {
                    const list = response.result.UtilityservicesplanningList.filter(e => e.status > this.util.approvedStatus);
                    equipmentdetailsList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return equipmentdetailsList;
    }
    public updateData(objData: Utilityservicesplanning) {
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Utilityservicesplanning) {
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Utilityservicesplanning) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();
        fData.append('eqpids', objData.eqpids+'');

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}

