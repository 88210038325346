import { Injectable } from '@angular/core';
import { ApiActions } from '../../service/common/util.service';
import { Subject } from 'rxjs';
import { RefItemGrade } from 'src/app/model/ref-master';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../service/common/app-config.service';
import { MsgService } from '../../service/common/msg.service';
import { CommonDataService } from '../../service/common/common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

export class RefItemGradeService {

	public pageName = 'Item Grade';
	public dataList = new Subject<Array<RefItemGrade>>();

	constructor(
		private http: HttpClient,
		private config: AppConfigService,
		private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
	) { }

	private getUrl(apiOption: ApiActions) {
		return this.config.ipAddress + this.config.itemRefItemGradeApi[apiOption];
	}

	public getDataRecords() {

		this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
		  .subscribe((response: any) => {
			if (response && response.result) {
			  const dataArray: Array<RefItemGrade> = [];
			  response.result.RefItemGradeList.map(element => {
				element.status = element.itemGradeStatus + '' === '1' ? true : false;
				dataArray.push(element);
			  });
			  this.dataList.next(dataArray);
			}
		  },
			err => {
			    this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
			});
	}

	public addData(objData: RefItemGrade) {
		const fData = new FormData();
		fData.append('itemGradeId', objData.itemGradeId + '');
		fData.append('categoryCode', objData.itemGradeCode);
		fData.append('categoryDesc', objData.itemGradeDesc);
		return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
	}

	public updateData(objData: RefItemGrade) {
		const fData = new FormData();
		fData.append('itemGradeId', objData.itemGradeId + '');
		fData.append('categoryCode', objData.itemGradeCode);
		fData.append('categoryDesc', objData.itemGradeDesc);
		return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
	}

	public deleteData(id: number) {
		const fData = new FormData();
		fData.append('itemGradeId', id + '');
		return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
	}

	public updateStatus(objData: RefItemGrade) {
		const fData = new FormData();
		fData.append('stat', objData.status ? '1' : '0');
		fData.append('itemGradeId', objData.itemGradeId + '');
		return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
	}
}
