import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

export enum ApiActions {
  Create = 'add',
  Update = 'edit',
  Delete = 'delete',
  Get = 'get',
  GetSingle = 'getSingle',
  UpdateStatus = 'updateStatus',
  DraftSave = 'draftSave',
  Reset = 'reset',
  Customerorderproducts = 'customerorderproducts',
  Permissions = 'permissions',
  GetUserPermissions = 'get-user-permissions',
  ChangeRole = 'change-role',
  ChangePassword = 'change-password',
  getTable = 'getTable',
  SaveobserveTable = 'SaveobserveTable',
  GetTasks = 'GetTasks',
  EditTask = 'EditTask',
  AddTask = 'AddTask',
  ChangeTaskStatus = 'ChangeTaskStatus',
  DeleteTask = 'DeleteTask',
  GetFiless = 'GetFiless',
  DeleteFile = 'DeleteFile',
  AddFiles = 'AddFiles',
}

export enum UserType {
  SuperAdmin = 0,
  Admin = 1,
  Approver = 2,
  Reviewer = 3,
  Creator = 4
}

export interface ActionState {
  canEdit: boolean;
  canDelete: boolean;
  canEditstatus : boolean;
}

export const unitShifts: Array<{ label: string, value: string }> = [
  { value: '1', label: '1St Shift' },
  { value: '2', label: '2nd Shift' },
  { value: '3', label: '3rd Shift' },
  { value: 'G', label: 'General Shift' }
];

export enum UserRoleValue {
  ToReviewer = 'Send to Reviewer',
  ToApprover = 'Send to Approver',
  Approve = 'Approve'
}

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  /**
   * Returns the user Role.
   */
  public get getUserRole() {
    return Number(localStorage.getItem('userRoleId')) as UserType;
  }
  public getUserRoleid() {
    return Number(localStorage.getItem('userRoleId')) as UserType;
  }

  public approvedStatus = 5;

  /**
   * Returns the action text on the button.
   */
  public roleActionType() {

    var workflowlevel: string = localStorage.getItem('workflowlevel');

    if (parseInt(workflowlevel) == 1) {
          return 'Approve';
    } else if (parseInt(workflowlevel) == 2) {
      switch (this.getUserRole) {
        case UserType.Creator: return 'Send to Approver';
        case UserType.Approver: return 'Approve';
      }
    } else if (parseInt(workflowlevel) == 3) {
      switch (this.getUserRole) {
        case UserType.Creator: return 'Send to Reviewer';
        case UserType.Reviewer: return 'Send to Approver';
        case UserType.Approver: return 'Approve';
      }
    }
 
  }
  public ProductstatusValue(status: number) {
    if (status == 1 || status == 0) {
      return '<label class="badge badge-danger"> Draft </label>';
    } else if (status == 2 || status == 3) {
      return '<label class="badge badge-warning"> InReview </label>';
    } else if (status == 4 || status == 5) {
      return '<label class="badge badge-warning"> InApprove </label>';
    } else if (status == 6) {
      return '<label class="badge badge-success"> Approved </label>';
    }

  }
  public ProductExestatusValue(status: number) {
    if (status == 1) {
      return '<label class="badge badge-danger"> HOLD </label>';
    } else if (status == -1) {
      return '<label class="badge badge-warning"> DELAY </label>';
    } else if (status == 0) {
      return '<label class="badge badge-success"> ONTIME </label>';
    }

  }

  public ProductExestatusPlannValue(status: number) {
    if (status == 1) {
      return '<label class="badge badge-danger"> NOT PLANNED </label>';
    } else if (status == -1) {
      return '<label class="badge badge-warning"> DELAY </label>';
    } else if (status == 0) {
      return '<label class="badge badge-success"> ONTIME </label>';
    }

  }

  public eqpExestatusValue(status: number) {
    if (status == 1) {
      return '<label class="badge badge-danger"> BREAKDOWN </label>';
    } else if (status == -1) {
      return '<label class="badge badge-warning"> IDEAL </label>';
    } else if (status == 0) {
      return '<label class="badge badge-success"> In Use </label>';
    }

  }
  public statusValue(status: number) {
    switch (this.getUserRole) {
      case UserType.Creator:
        if (status == 1 || status == 0) {
          return '<label class="badge badge-warning"> Draft </label>';
        } else if (status == 2 || status == 3) {
          return '<label class="badge badge-inverse"> InReview </label>';
        } else if (status == 4 || status == 5) {
          return '<label class="badge badge-info"> InApprove </label>';
        } else if (status == 6) {
          return '<label class="badge badge-success"> Approved </label>';
        }
        break;
      case UserType.Reviewer:
        if (status == 1 || status == 0) {
          return '<label class="badge badge-warning"> Draft </label>';
        } else if (status == 2 || status == 3) {
          return '<label class="badge badge-inverse"> InReview </label>';
        } else if (status == 4 || status == 5) {
          return '<label class="badge badge-info"> InApprove </label>';
        } else if (status == 6) {
          return '<label class="badge badge-success"> Approved </label>';
        }
        break;
      case UserType.Approver:
        if (status == 1 || status == 0) {
          return '<label class="badge badge-warning"> Draft </label>';
        } else if (status == 2 || status == 3) {
          return '<label class="badge badge-inverse"> InReview </label>';
        } else if (status == 4 || status == 5) {
          return '<label class="badge badge-info"> InApprove </label>';
        } else if (status == 6) {
          return '<label class="badge badge-success"> Approved </label>';
        }
    }
    return '';
  }
  public BatchstatusValue(status: number) {

    if (status == 1) {

      return '<label class="badge badge-warning" > Batch in Hold </label>';
    } else if (status == 0) {
      return '<label class="badge badge-info">Batch Running </label>';
    } else if (status == 2) {

      return '<label class="badge badge-success" > Batch Completed </label>';
    } else if (status == 3) {

      return '<label class="badge badge-danger" > Batch Cancled </label>';
    }

  }

  public SalesmaintablestatusValue(status: number) {

    if (status == 2) {
      return '<label class="badge badge-success" >Quotated</label>';
    } else if (status == 3) {
      return '<label class="badge badge-success" >Order Received</label>';
    } else if (status == 4) {
      return '<label class="badge badge-success" >Dispatched</label>';
    }

  }
  public IndentmaintablestatusValue(status: number) {

    if (status == 2) {
      return '<label class="badge badge-primary" >Indent Raised</label>';
    } else if (status == 3) {
      return '<label class="badge badge-success" >Indent Issued</label>';
    } 

  }

  public PurchasemaintablestatusValue(status: number) {

    if (status == 2) {
      return '<label class="badge badge-success" >Quotated</label>';
    } else if (status == 3) {
      return '<label class="badge badge-success" >PO Released</label>';
    } else if (status == 4) {
      return '<label class="badge badge-success" >Goods Received</label>';
    }

  }

  public RawmeterialqurentineRecStatus(status: number) {

    if (status == 1) {
      return '<label class="badge badge-primary" >Send to QC</label>';
    } else if (status == 2) {
      return '<label class="badge badge-warning" >In Analysis</label>';
    } else if (status == 3) {
      return '<label class="badge badge-success" >Qc Pass</label>';
    }

  }

  public InprocessanalysisRecStatus(status: number) {

    if (status == 1) {
      return '<label class="badge badge-success" >QC Passed</label>';
    } else if (status == 2) {
      return '<label class="badge badge-warning" >Rework</label>';
    } else if (status == 3) {
      return '<label class="badge badge-help" >OOS Analysis</label>';
    }

  }

  public qualityRecStatus(status: number) {

    if (status == 1) {
      return '<label class="badge badge-success" >In Analysis</label>';
    } else if (status == 2) {
      return '<label class="badge badge-warning" >Rework</label>';
    } else if (status == 3) {
      return '<label class="badge badge-help" >OOS Analysis</label>';
    }

  }
  public analysisRecStatus(status: number) {

    if (status == 1) {
      return '<label class="badge badge-success" >Pass</label>';
    } else if (status == 2) {
      return '<label class="badge badge-warning" >Rework</label>';
    } else if (status == 3) {
      return '<label class="badge badge-help" >OOS Analysis</label>';
    }

  }

  public qualatytestRecStatus(status: number) {

    if (status == 2) {
      return '<label class="badge badge-primary" >In Analysis</label>';
    } else if (status == 3) {
      return '<label class="badge badge-success" >Pass</label>';
    } else if (status == 4) {
      return '<label class="badge badge-help" >OOS Analysis</label>';
    }

  }

  /**
   * This role level is to find the actions that user can do.
   */
  public getRoleLevel(status: number): ActionState {
    const actionState: ActionState = { canEdit: false,canEditstatus:false, canDelete: false };
    switch (this.getUserRole) {
      case UserType.Creator:
        // tslint:disable-next-line: triple-equals
        if (status == 1 || status == 0) {
          actionState.canEdit = true;
          actionState.canDelete = true;
        }
        break;
      case UserType.Reviewer:
        // tslint:disable-next-line: triple-equals
        if (status == 2 || status == 3) {
          actionState.canEdit = true;
        }
        break;
      case UserType.Approver:
        // tslint:disable-next-line: triple-equals
        if (status == 4 || status == 5) {
          actionState.canEdit = true;
        }
        break;
      case UserType.Admin:
        actionState.canEdit = true;
        actionState.canDelete = true;
        break;
    }
    return actionState;
  }
  public getBatchstatuslevel(status: number): ActionState {
    const actionState: ActionState = { canEdit: false, canEditstatus:false, canDelete: false };
    switch (this.getUserRole) {
      case UserType.Creator:
        // tslint:disable-next-line: triple-equals
        if (status == 0) {
          actionState.canEdit = true;
          actionState.canEditstatus = true;
          // actionState.canDelete = true;
        }
        break;
      case UserType.Reviewer:
        // tslint:disable-next-line: triple-equals
        if (status == 0) {
          actionState.canEdit = true;
          actionState.canEditstatus = true;
          // actionState.canDelete = true;
        }
        break;
      case UserType.Approver:
        // tslint:disable-next-line: triple-equals
        if (status == 0) {
          actionState.canEdit = true;
          actionState.canEditstatus = true;
          //  actionState.canDelete = true;
        }
        break;
      case UserType.Admin:
        actionState.canEdit = true;
        actionState.canEditstatus = true;
        actionState.canDelete = true;
        break;
    }
    return actionState;
  }
  /**
   * Returns the Draft save status value based on user role
   */
  public getDraftStatus() {
    var workflowlevel: string = localStorage.getItem('workflowlevel');

    if (parseInt(workflowlevel) == 1) {
         return 1;
    } else if (parseInt(workflowlevel) == 2) {
      switch (this.getUserRole) {
        case UserType.Creator: return 1;
        case UserType.Approver: return 5;
      }
    } else if (parseInt(workflowlevel) == 3) {
      switch (this.getUserRole) {
        case UserType.Creator: return 1;
        case UserType.Reviewer: return 3;
        case UserType.Approver: return 5;
      }
    }
  }

  /**
   * Returns the save status value based on user role
   */
  public getSaveStatus() {
    var workflowlevel: string = localStorage.getItem('workflowlevel');

    if (parseInt(workflowlevel) == 1) {
         return 6;
    } else if (parseInt(workflowlevel) == 2) {
      switch (this.getUserRole) {
        case UserType.Creator: return 4;
        case UserType.Approver: return 6;
      }
    } else if (parseInt(workflowlevel) == 3) {
      switch (this.getUserRole) {
        case UserType.Creator: return 2;
        case UserType.Reviewer: return 4;
        case UserType.Approver: return 6;
      }
    }
  }

  public getRecWith(recWith: number) {
    switch (this.getUserRole) {
      case UserType.Creator: return recWith == 0 ? true : false;
      case UserType.Reviewer: return recWith == 1 ? true : false;
      case UserType.Approver: return recWith == 2 ? true : false;
    }
  }

  /**
   * Converts the Date to YYYY-MM-DD format.
   * @param d Date value.
   */
  public getDate(d: Date) {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    month = (month.length < 2) ? '0' + month : month;
    day = (day.length < 2) ? '0' + day : day;
    return [year, month, day].join('-');
  }
  public gettwentyfourhoursDate(d: Date) {
    const nd = new Date(d);
    let hour = nd.getHours();
    let mins = nd.getMinutes();
    const sec = nd.getSeconds();
    return [hour, mins, sec].join(':');
  }
  /** To Date format dd/MM/yyyy */
  public getDateFormat(d: Date) {
    try {
      return new DatePipe('en-US').transform(d, 'dd/MM/yyyy');
    } catch (ex) {
      return d;
    }
  }
  public getDateTimeFormat(d: Date) {
    try {
      return new DatePipe('en-US').transform(d, 'dd/MM/yyyy HH:ss');
    } catch (ex) {
      return d;
    }
  }
  public gettwentyfourhoursTimeFormat(d: Date) {
    try {
      return new DatePipe('en-US').transform(d, 'd/MM/yyyy H:mm');
    } catch (ex) {
      return d;
    }
  }
  public convert2DDMMYYYY(data: string) {
    const result = [];
    if (data && data.indexOf(',') >= 0) {
      const dataArray = data.split(',');
      dataArray.forEach(item => {
        result.push(new Date(item.trim()));
      });
    } else if (data && data.trim().length > 0) {
      result.push(new Date(data.trim()));
    }
    return data ? result : '';
  }

  public toDDMMYYYY(d: string) {
    const res = (d.indexOf(' ') > 0) ? d.split(' ')[0].split('-') : d.split('-');
    return res[2] + '/' + res[1] + '/' + res[0];
  }

  public toArrayOfDate(dateLst: string) {
    let dates = [];
    if (dateLst.indexOf(',') > 0) {
      dates = dateLst.split(', ');
    } else {
      dates.push(dateLst);
    }
    const result = [];

    dates.forEach(item => result.push(this.toDateObject(item)));
    return result;
  }

  public toDateObject(d: string): Date {
    const date = d.split('-');
    return new Date(date[1] + '/' + date[0] + '/' + date[2]);
  }

  public getDistinctData(array: Array<{ label: string, value: string }>): Array<{ label: string, value: string }> {

    const distinctData = Array.from(new Set(array.map(s => s && s.value)))
      .map(v => {
        return {
          label: v,
          value: v
        };
      });

    distinctData.unshift({ label: 'All', value: null });

    return distinctData as Array<{ label: string, value: string }>;
  }

}
