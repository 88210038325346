import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Parameterwiseutility } from 'src/app/model';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class ParameterwiseutilityService {

    public pageName = 'Parameter Wise Utility Equipment';
    public dataList = new Subject<Array<Parameterwiseutility>>();
    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private common: CommonDataService,
        private msgSvc: MsgService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.ParameterwiseutilityApi[apiOption];
    }

    public getParameters() {
        const url = this.config.ipAddress + this.config.ParameterApi[ApiActions.Get];
        const dataArray: Array<any> = [];
        const data = new Subject<Array<any>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.ParameterList.map(element => {
                        element.status = element.status == '1' ? true : false;
                        dataArray.push(element);
                    });
                    data.next(dataArray);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }

    public getEqpsubtype() {
        const url = this.config.ipAddress + this.config.ParameterApi[ApiActions.getTable];
        const dataArray: Array<any> = [];
        const data = new Subject<Array<any>>();
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.EqpsubtypeList.map(element => {
                        element.status = element.status == '1' ? true : false;
                        dataArray.push(element);
                    });
                    data.next(dataArray);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }

    public getEquipements(EqpSubtype: number) {
        const resultList = new Subject<Array<any>>();
        const url = this.config.ipAddress + this.config.ParameterApi[ApiActions.GetSingle];
        const fData = new FormData();
        fData.append('EqpSubtype', EqpSubtype + '');
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.EqpList) {
                    resultList.next(response.result.EqpList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return resultList;
    }
    public getItemList(productMasterId) {
        const fData = new FormData();
        fData.append('api_id', productMasterId + '');
        const resultList = new Subject<Array<Parameterwiseutility>>();
        const url = this.config.ipAddress + this.config.masterFormulaApi[ApiActions.GetSingle];
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.ItemMasterList) {
                    const list = response.result.ItemMasterList.filter(e => e.status > this.util.approvedStatus);
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return resultList;
    }

    public getDataRecords() {
        let resultList = new Array<Parameterwiseutility>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.ParameterwiseutilityList) {
                    resultList = response.result.ParameterwiseutilityList;
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
    }

    public updateData(objData: Parameterwiseutility) {
        // objData.status = this.util.getSaveStatus();
        console.log(objData);
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Parameterwiseutility) {
        // objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.Create), objData);
    }

    private Save(action: string, objData: Parameterwiseutility) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append('eqpparamid', objData.eqpparamid + '');
        fData.append('paramid', objData.paramid + '');
        fData.append('eqptypeid', objData.eqptypeid + '');
        fData.append('eqpsubtypeid', objData.eqpsubtypeid + '');
        fData.append('eqpids', objData.eqpids + '');


        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }

}
