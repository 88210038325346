import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Leavestructure } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class LeavestructureService {

  public pageName = 'Leave Structure';
  public dataList = new Subject<Array<Leavestructure>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.LeavestructureApi[apiOption];
  }
  public getDataRecords() {
    let resultList = new Array<Leavestructure>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        console.log(response);
        if (response && response.result && response.result.LeavestructureList) {
          resultList = response.result.LeavestructureList;
          this.dataList.next(resultList);
        }
      },
        err => {
          //this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });


  }
  public getLeavecomponents() {
    const resultList = new Subject<Array<any>>();
    const url = this.config.ipAddress + this.config.LeavecomponentsApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.LeavecomponentsList) {
          const list = response.result.LeavecomponentsList;
          resultList.next(list);
        }
      },
        err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });
    return resultList;
  }
  public getEmployee() {
    const resultList = new Subject<Array<any>>();
    const url = this.config.ipAddress + this.config.EmployeeDetailsApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.Employeedetails) {
          const list = response.result.Employeedetails;
          resultList.next(list);
        }
      },
        err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });
    return resultList;
  }
  public saveDraft(objData: Leavestructure) {
    return this.Save(this.getUrl(ApiActions.Create), objData);

  }
  public updateData(objData: Leavestructure) {
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  private Save(action: string, objData: Leavestructure) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('emp_id', objData.emp_id + '');

    const items = objData.items.map(e => {
        return {
          leavestru_id: e.leavestru_id,
          leavecomp_id: e.leavecomp_id,
          leavecomp_val: e.leavecomp_val,

        };
    });

    fData.append('items', JSON.stringify(items));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
}
