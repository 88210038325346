import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { EventService } from '../../../core/services/event.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppConfigService, LeftMenuService } from 'src/app/service';
import { Subscription } from 'rxjs';
export interface Helpcontent {
  search_tag: string;

}
export const defaultHelpcontent = {
  search_tag: ''
};
@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.scss']
})
export class RightsidebarComponent implements OnInit {
  activityList: any[];
  visible: boolean = false;
  selectedItemId: number;
  videoLinkSrc: string | null = null;
  private subscription = new Subscription();
  showDialog(hcont_id: number, link: string) {
    this.visible = true;
    this.selectedItemId = hcont_id;
    this.videoLinkSrc = this.config.ipAddress + 'uploads/hcont_link/' + link;

    // this.displayDialog = true;
  }


  public source: SafeUrl;
  public dataObj: Helpcontent = defaultHelpcontent;
  // public dataObj.search_tag: string;
  constructor(private eventService: EventService, private menuSvc: LeftMenuService, private config: AppConfigService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.subscription.add(
      this.menuSvc.getHelpContentsList().subscribe(val => {
        this.activityList = val;
        console.log(this.activityList);
      }));
  }

  setVideoSourceToObjectUrl = (url: string) => fetch(url)
    .then(response => response.blob()) //Encode the response as a blob
    .then(blob => {
      // Create an object url from the blob;
      var blobUrl = URL.createObjectURL(blob)

      // Create a safe url and set it to the video source.
      this.source = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
    });


  public onProductChange() {
    //console.log(event.value.value);
    console.log(this.dataObj.search_tag);
    //console.log(event.value);
    // event = this.dataObj.search_tag ;
    //console.log(this.dataObj.type_id);
    this.menuSvc.getsearchhelpcontent(this.dataObj.search_tag)
      .subscribe(val => {
        this.activityList = val;
      });
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    this.eventService.broadcast('changeLeftSidebarType', 'default');
    this.eventService.broadcast('changeLeftSidebarTheme', 'default');
    this.eventService.broadcast('changeLayoutWidth', 'default');
    this.eventService.broadcast('changeLayout', layout);
    this.hide();
  }

  /**
   * Change the left-sidebar theme
   * @param theme Change the theme
   */
  changeLeftSidebarTheme(theme: string) {
    this.eventService.broadcast('changeLayout', 'vertical');
    this.eventService.broadcast('changeLeftSidebarTheme', theme);
    this.eventService.broadcast('changeLeftSidebarType', 'default');
    this.hide();
  }

  /**
   * Change the layout
   * @param type Change the layout type
   */
  changeLeftSidebarType(type: string) {
    this.eventService.broadcast('changeLayout', 'vertical');
    this.eventService.broadcast('changeLeftSidebarType', type);
    this.hide();
  }

  /**
   * Change the layout width
   * @param width string 
   */
  changeLayoutWidth(width: string) {
    this.eventService.broadcast('changeLayoutWidth', width);
    this.hide();
  }


  /**
   * Hide the sidebar
   */
  public hide() {
    document.body.classList.remove('right-bar-enabled');
  }

  public viewImage(file) {
    return this.config.ipAddress + 'uploads/hcont_link/' + file;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
