import {Injectable} from '@angular/core';
import {ApiActions,UtilService} from '../common/util.service';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '../common/app-config.service';
import {MsgService} from '../common/msg.service';
import {CommonDataService} from '../common/common-data.service';
import {Rawmaterialsplanning} from 'src/app/model';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class RawmaterialsplanningService {

  public pageName = 'Raw Materials Planning';
  public dataList = new Subject < Array < Rawmaterialsplanning >> ();
  constructor(
      private http: HttpClient,
      private config: AppConfigService,
      private msgService: MsgService,
      private util: UtilService,
      private common: CommonDataService,
  private msgSvc: MsgService,
  private router: Router
  ) {}

  private getUrl(apiOption: ApiActions) {
      return this.config.ipAddress + this.config.RawmaterialplanningApi[apiOption];
  }
  public getDataRecords() {
    const dataArray: Array<Rawmaterialsplanning> = [];
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
        .subscribe((response: any) => {
            if (response && response.result && response.result.RawmaterialsplanningList) {
                response.result.RawmaterialsplanningList.map(element => {
                    dataArray.push(element);
                });
            }
            this.dataList.next(dataArray);
        },
            err => {
                this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
            });
}
}

