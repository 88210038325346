import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProductGrade
  , ProductCategory
  , ProductPharmacopiea
  , ProductPhysicalForm
  , ProductMaster
  , INTItem
  , ProductUOM } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';
import { CommonDataService } from '../common/common-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProductMasterInventoryService {

  public pageName = 'Product Inventory';
  public dataList = new Subject<Array<ProductMaster>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
	private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.productinventoryserviceApi[apiOption];
  }

  public getCategories(): BehaviorSubject<Array<ProductCategory>> {
    const url = this.config.ipAddress + this.config.productCategoryApi[ApiActions.Get];
    const dataArray: Array<ProductCategory> = [];
    const data = new BehaviorSubject<Array<ProductCategory>>([]);
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.ProductCategory.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return data;
  }

  public getGrades() {
    const url = this.config.ipAddress + this.config.productGradeApi[ApiActions.Get];
    const dataArray: Array<ProductGrade> = [];
    const data = new BehaviorSubject<Array<ProductGrade>>([]);

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.ProductGrade.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return data;
  }

  public getPharmacopiea() {
    const url = this.config.ipAddress + this.config.productPharmacopieaApi[ApiActions.Get];
    const dataArray: Array<ProductPharmacopiea> = [];
    const data = new BehaviorSubject<Array<ProductPharmacopiea>>([]);

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.ProductPharmacopiea.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return data;
  }

 public getProductphysicalform() {
    const url = this.config.ipAddress + this.config.ProductPhysicalFormApi[ApiActions.Get];
    const dataArray: Array<ProductPhysicalForm> = [];
    const data = new BehaviorSubject<Array<ProductPhysicalForm>>([]);

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.ProductPhysicalForm.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return data;
  }

  public getUOMCodes() {
    const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
    const dataArray: Array<ProductUOM> = [];
    const data = new BehaviorSubject<Array<ProductUOM>>([]);

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.UnitsOfMeasurement.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return data;
  }
  public getItemsList(pcId: number) {
    const url = this.config.ipAddress + this.config.intDetailsApi[ApiActions.Get];
    const data = new Subject<Array<INTItem>>();
    const fData = new FormData();
    fData.append('pconfigId', pcId + '');
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.productIntList;
          data.next(result);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return data;
  }
  public getDataRecords() {
    const dataArray: Array<ProductMaster> = [];
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
			response.result.productMasterList.map(element => {
				dataArray.push(element);
			});
        }
        this.dataList.next(dataArray);
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }
  public getDataTableRecords() {
    const dataArray: Array<ProductMaster> = [];
    this.http.post(this.getUrl(ApiActions.getTable), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
			response.result.productMasterList.map(element => {
				dataArray.push(element);
			});
        }
        this.dataList.next(dataArray);
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }
  public updateData(objData: ProductMaster) {
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: ProductMaster) {
    objData.status = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: ProductMaster) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
	console.log(objData);
    fData.append('prodStatus', objData.status + '');
    fData.append('prodId', objData.productMasterId + '');
    fData.append('prodCode', objData.productMasterCode);
    fData.append('prodName', objData.productMasterName);
    fData.append('prodCatId', objData.productCategoryId + '');
    fData.append('prodGradeId', '0');
    fData.append('pharmaCopiId', objData.pharmaCopiId + '');
    fData.append('prodForm', objData.productForm_id+ '');
    fData.append('prodUOM', objData.productUOM + '');
    fData.append('prodSpecsDoc', objData.productSpecDoc);
	fData.append('productMsdsDoc', objData.productMsdsDoc);
	
	    const items = objData.items.map(e => {
      return {
        INTproductMasterCode: e.INTproductMasterCode,
        INTproductMasterName: e.INTproductMasterName,
        INTproductMasterMake: 'Make'
      };
    });
	fData.append('intercepts', JSON.stringify(items));
	//fData.append('intercepts', objData.items+ '');
    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
	public deleteData(id: number) {
		const fData = new FormData();
		fData.append('prodId', id + '');
		return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
	}
}
