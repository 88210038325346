import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import {
  ProductMaster
  , ProductUOM
  , ItemMaster,
  MasterFormula
} from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class MasterFormulaService {

  public pageName = 'Bill of Materials/ Master Formula/ Recipe';
  public dataList = new Subject<Array<MasterFormula>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.masterFormulaApi[apiOption];
  }

  public getUOMCodes() {
    const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.GetSingle];
    const dataArray: Array<ProductUOM> = [];
    const data = new Subject<Array<ProductUOM>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.UnitsOfMeasurement.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return data;
  }

  public getProducts() {
    const resultList = new Subject<Array<ProductMaster>>();
    const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
          const list = response.result.productMasterList.filter(e => e.status > this.util.approvedStatus);
          resultList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return resultList;
  }

  public getItemMasters() {
    const resultList = new Subject<Array<ItemMaster>>();
    const url = this.config.ipAddress + this.config.itemMasterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.ItemMasterList) {
          const list = response.result.ItemMasterList.filter(e => e.status > this.util.approvedStatus);
          resultList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return resultList;
  }
   public getItemList(productMasterId) {
	   const fData = new FormData();
	   fData.append('api_id',productMasterId + '');
    const resultList = new Subject<Array<ItemMaster>>();
    const url = this.config.ipAddress + this.config.masterFormulaApi[ApiActions.GetSingle];
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.ItemMasterList) {
          const list = response.result.ItemMasterList.filter(e => e.status > this.util.approvedStatus);
          resultList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return resultList;
  }
 
  public getCustomersforDropdown(prodId) {
    const productSpecMasterList = new Subject<Array<any>>();
    const fData = new FormData();
    fData.append('prodId', prodId + '');
    const url = this.config.ipAddress + this.config.customerMasterApi[ApiActions.DraftSave];
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.Customermaster) {
          const list = response.result.Customermaster;
          productSpecMasterList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });   
        });
    return productSpecMasterList;
  }

  public getDataRecords() {
    let resultList = new Array<MasterFormula>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.MasterFormula) {
          resultList = response.result.MasterFormula;
          this.dataList.next(resultList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
  }

  public updateData(objData: MasterFormula) {
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  
  public updateDataApprove(objData: MasterFormula) {
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Delete), objData);
  }
  
  public saveDraft(objData: MasterFormula) {
    objData.status = this.util.getDraftStatus();
    return  this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  public savecloneDraft(objData: MasterFormula) {
    objData.status = this.util.getDraftStatus();
    return  this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: MasterFormula) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('status', objData.status + '');
    fData.append('ProdSpecNumber', objData.ProdSpecNumber + '');
    fData.append('custId', objData.customerId + '');
    fData.append('formulaId', objData.formulaId + '');
    fData.append('prodId', objData.prodId + '');
    fData.append('isBatch', objData.isBatch);
    fData.append('batchQty', objData.batchQty + '');
    fData.append('uomId', objData.uomId + '');

    const items = objData.items.map(e => {
      return {
        itemId: e.itemId,
        quantity: e.quantity,
        uomId: e.uomId,
		is_intermediate: e.is_intermediate
      };
    });

    fData.append('items', JSON.stringify(items));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      // if (response) {
      //   this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
      //   responseMsg.next('');
      // }

      if (response.result.status_code == 201){
        this.msgService.addWarnMsg({ summary: this.pageName, detail: response.result.status });
        return false;
      }
      else if (response.result.status_code == 200) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }

    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
}
