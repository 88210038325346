import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Router } from '@angular/router';
import {
  RefMasterField
} from 'src/app/model';

@Injectable({
  providedIn: 'root'
})
export class RefMasterFieldsService {
  public pageName = 'Fields';
  public dataList = new Subject<Array<RefMasterField>>();
	constructor(
		private http: HttpClient,
		private config: AppConfigService,
		private msgService: MsgService,
		private util: UtilService  ,
		 private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
	) { }
	
	private getUrl(apiOption: ApiActions) {
	return this.config.ipAddress + this.config.fieldsApi[apiOption];
  }
   public getDataRecords() {
    const dataArray: Array<RefMasterField> = [];
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.RefMasterFieldList) {
          response.result.RefMasterFieldList.map(element => {
            dataArray.push(element);
          });
        }
        this.dataList.next(dataArray);
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }
 private fillData(objData: RefMasterField) {
    const fData = new FormData();
    fData.append('fieldId', objData.fieldId + '');
    fData.append('fieldName', objData.fieldName);
    fData.append('status', objData.status ? '1' : '0');
    return fData;
  }
   public updateData(objData: RefMasterField) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);

  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('fieldId', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }
  public Save(objData: RefMasterField) {
     const fData = new FormData();
    fData.append('fieldName', objData.fieldName + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }
}
