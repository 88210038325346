import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Inprocessanalysis, ProductUOM, ProductMaster, Customer } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class InprocessanalysisService {

    public pageName = 'Inprocess Product Test Analysis Report';
    public dataList = new Subject<Array<Inprocessanalysis>>();
    public OrderdataList = new Subject<Array<Inprocessanalysis>>();
    public OrdereditdataList = new Subject<Array<Inprocessanalysis>>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.InprocessanalysisApi[apiOption];
    }

    public getUOMCodes() {
        const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
        const data = new Subject<Array<ProductUOM>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.UnitsOfMeasurement;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }

    public getProductsData() {
        const resultList = new Subject<Array<ProductMaster>>();
        const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Customerorderproducts];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.productMasterList) {
                    const list = response.result.productMasterList;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return resultList;
    }

    public getCustomers() {
        const url = this.config.ipAddress + this.config.SupplierMasterApi[ApiActions.Get];
        const data = new Subject<Array<Customer>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Supplierlistmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }
    public getCUrrancyList(): Subject<Array<any>> {
        const url = this.config.ipAddress + this.config.currencyserviceApi[ApiActions.Get];
        const countrylist: Array<any> = [];
        const result = new Subject<Array<any>>();
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.Currency.map(element => {
                        // element.status = Number(element.recStatus) === 1 ? true : false;
                        countrylist.push(element);
                    });
                    result.next(countrylist);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return result;
    }
    public getEnquiryTypedropdownData(fromdate: Date, todate: Date) {
        const url = this.config.ipAddress + this.config.InprocessanalysisApi[ApiActions.Create];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("fromdate", this.util.getDate(fromdate));
        fData.append("todate", this.util.getDate(todate));
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }
    public getEnquiryTypedropdownDataSupplier(cust_id: number) {
        console.log(cust_id);
        const url = this.config.ipAddress + this.config.InprocessanalysisApi[ApiActions.GetSingle];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("cust_id", cust_id + '');
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }
    public getRecivablecodedropdownData(OrderId: number) {
        const url = this.config.ipAddress + this.config.InprocessanalysisApi[ApiActions.UpdateStatus];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("order_id", OrderId + "");
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }
    public getDataRecords() {
        let resultList = new Array<Inprocessanalysis>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.InprocessanalysisList) {
                    resultList = response.result.InprocessanalysisList.map(e => {

                        e.QuotDate = new Date(e.QuotDate);
                        e.OrdDate = new Date(e.OrdDate);
                        e.IssuedDate = new Date(e.IssuedDate);
                        e.sample_on = new Date(e.sample_on);
                        e.BatchDate = new Date(e.BatchDate);
                        e.TARDate = new Date(e.TARDate);

                        return e;
                    });
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
    }
    public getOrderDataRecords(orderId: number) {
        let resultList = new Array<Inprocessanalysis>();
        const fData = new FormData();

        fData.append("order_id", orderId + "");
        this.http.post(this.getUrl(ApiActions.Delete), fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.GoodsissueagainestsalesordersList) {
                    resultList = response.result.GoodsissueagainestsalesordersList.map(e => {

                        e.QuotDate = new Date(e.QuotDate);
                        e.OrdDate = new Date(e.OrdDate);
                        e.IssuedDate = new Date(e.IssuedDate);
                        e.BatchDate = new Date(e.BatchDate);
                      //  e.sample_on = new Date(e.sample_on);

                        return e;
                    });
                    this.OrderdataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
    }

    public getOrderEditDataRecords(orderId: number) {
        let resultList = new Array<Inprocessanalysis>();
        const fData = new FormData();

        fData.append("order_id", orderId + "");
        this.http.post(this.getUrl(ApiActions.getTable), fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.GoodsissueagainestsalesordersList) {
                    resultList = response.result.GoodsissueagainestsalesordersList.map(e => {

                        e.QuotDate = new Date(e.QuotDate);
                        e.OrdDate = new Date(e.OrdDate);
                        e.IssuedDate = new Date(e.IssuedDate);
                        e.BatchDate = new Date(e.BatchDate);
                        e.sample_on = new Date(e.sample_on);

                        return e;
                    });
                    this.OrdereditdataList.next(resultList);
                }
            },
                err => {
                   // this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
    }
    public updateInprocessanalysisRecStatus(objData: Inprocessanalysis,status:number) {
        let resultList = new Array<Inprocessanalysis>();
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append("step_id", objData.step_id + "");
        fData.append("BatchNum", objData.BatchNum + "");
        fData.append("report_id", objData.report_id + "");
        fData.append("rec_status", status + "");
        this.http.post(this.getUrl(ApiActions.UpdateStatus), fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response) {
                    this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                    responseMsg.next('');
                }
            },
                err => {
                  //  this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
    }
    public updateData(objData: Inprocessanalysis) {
        console.log(objData);
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Inprocessanalysis) {
        objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }


    private Save(action: string, objData: Inprocessanalysis) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();


        fData.append("report_id", objData.report_id + "");
        fData.append("sample_code", objData.sample_code + "");
        fData.append("tarno_id", objData.tarno_id + "");
        fData.append("prod_id", objData.prod_id + "");
        fData.append("chemist_id", objData.chemist_id + "");
        fData.append("sample_by", objData.sample_by + "");
        fData.append("sample_on", this.util.getDate(objData.sample_on));

        const items = objData.items.map((e) => {
            return {
                reportdtl_id: e.reportdtl_id,
                report_id: objData.report_id,
                param_id: e.param_id,
                param_name: e.ParamName,
                param_value: e.ParamVal,
                param_uom: e.uom_id,
                obsrv_val: e.ObsrvVal,
                obsrv_remark: e.ObsrvRemark
            };
        });

        fData.append('items', JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}

