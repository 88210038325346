import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductionOrderSequence } from 'src/app/model';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { ApiActions, UtilService } from '../common/util.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ProductionOrderSequenceGeneratorService {

  public pageName = ' Production Order Sequence Generator';
  public dataList = new Subject<Array<ProductionOrderSequence>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.prioritySequenceGeneratorApi[apiOption];
  }

  public getDataRecords(fromDate: Date, toDate: Date) {
    let resultList = new Array<ProductionOrderSequence>();
    const fData = new FormData();
    fData.append('fromDate', this.util.getDate(fromDate));
    fData.append('toDate', this.util.getDate(toDate));
    this.http.post(this.getUrl(ApiActions.Get), fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          if(response.result.status_code == 1){
            this.msgService.addInfoMsg({ summary: this.pageName, detail: 'No Plans exit between this Two Dates' });

          }else{
            resultList = response.result.Sequencegenerator.map(e => {
              e.custPodate = new Date(e.custPodate);
              return e;
            });
            this.dataList.next(resultList);

          }
         
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
  }

  public updateData(objList: Array<ProductionOrderSequence>) {
    return this.Save(this.getUrl(ApiActions.Update), objList, this.util.getSaveStatus());
  }

  private Save(action: string, objList: Array<ProductionOrderSequence>, statusVal: number) {

    const responseMsg = new Subject<string>();
    const fData = new FormData();

    const data = objList.map(obj => {
      return {
        customerOrderId: obj.custordId,
        prodId: obj.prodId,
        custPo: obj.custPo,
        priorityNum: obj.priorityNum,
        runStartDate: this.util.getDate(new Date(obj.runStartDate)),
        runEndDate: this.util.getDate(new Date(obj.runEndDate)),
        runNo: obj.runNo,
        planId: obj.planId,
        noOfBatches: obj.noOfBatches,
        lots: obj.Lots,
        batchNum: obj.batchNum,
        status: statusVal
      };
    });

    console.log('data to save -->', data);
    fData.append('data', JSON.stringify(data));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
}
