import { Injectable } from '@angular/core';
import { ProductSpecification, ProductParameter, ProductPharmacopiea, Customer, ProductGrade, ProductPhysicalForm } from 'src/app/model';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiActions, UtilService } from '../common/util.service';
import {
  ProductMaster
  , ProductUOM
} from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ProductSpecificationService {
  public pageName = 'Product Specifications';
  public dataList = new Subject<Array<ProductSpecification>>();
  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.productSpecificationApi[apiOption];
  }

  public getParameters() {
    const dataArray: Array<ProductParameter> = [];
    const data = new Subject<Array<ProductParameter>>();
    const url = this.config.ipAddress + this.config.productParameterApi[ApiActions.GetSingle];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Productparameter.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return data;
  }

  public getCustomers() {
    const url = this.config.ipAddress + this.config.customerMasterApi[ApiActions.GetSingle];
    const data = new Subject<Array<Customer>>();

    this.http.post(url, null, { headers: undefined })
        .subscribe((response: any) => {
            if (response && response.result) {
                const result = response.result.Customermaster;
                data.next(result);
            }
        },
            err => {
                this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
            });
    return data;
}

  public getPharmacopiea() {
    const url = this.config.ipAddress + this.config.productPharmacopieaApi[ApiActions.GetSingle];
    const dataArray: Array<ProductPharmacopiea> = [];
    const data = new BehaviorSubject<Array<ProductPharmacopiea>>([]);

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.ProductPharmacopiea.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return data;
  }

  public getUOMCodes() {
    const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.GetSingle];
    const dataArray: Array<ProductUOM> = [];
    const data = new Subject<Array<ProductUOM>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.UnitsOfMeasurement.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return data;
  }

  public getProductMasterRecords() {
    const productMasterList = new Subject<Array<ProductMaster>>();
    const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
          const list = response.result.productMasterList.filter(e => e.status > this.util.approvedStatus);
          productMasterList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return productMasterList;
  }
  public getProductSpecDropdownRecords(prodId,custId) {
    const productSpecMasterList = new Subject<Array<any>>();
    const fData = new FormData();
    fData.append('prodId', prodId + '');
    fData.append('custId', custId + '');
    const url = this.config.ipAddress + this.config.productSpecificationApi[ApiActions.GetSingle];
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productSpecList) {
          const list = response.result.productSpecList;
          productSpecMasterList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return productSpecMasterList;
  }

  public getProductSpecRecords() {
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.dataList.next(response.result.productSpecList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
  }

  public getGrades() {
    const url = this.config.ipAddress + this.config.productGradeApi[ApiActions.Get];
    const dataArray: Array<ProductGrade> = [];
    const data = new BehaviorSubject<Array<ProductGrade>>([]);

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.ProductGrade.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return data;
  }

  public getProductphysicalform() {
    const url = this.config.ipAddress + this.config.ProductPhysicalFormApi[ApiActions.GetSingle];
    const dataArray: Array<ProductPhysicalForm> = [];
    const data = new BehaviorSubject<Array<ProductPhysicalForm>>([]);

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.ProductPhysicalForm.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return data;
  }

  public updateData(objData: ProductSpecification) {
    objData.specStatus = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: ProductSpecification) {
    objData.specStatus = this.util.getDraftStatus();
    console.log(objData);
    //return false;
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: ProductSpecification) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('prodId', objData.prodId + '');
    fData.append('pharmaCopiId', objData.pharmaCopiId + '');
    fData.append('productGradeId', objData.productGradeId + '');
    fData.append('productForm_id', objData.productForm_id + '');
    fData.append('prodspec_number', objData.specno + '');
    fData.append('customerId', objData.cust_id + '');
    fData.append('prodspec_id', objData.prodspec_id + '');
    fData.append('prodSpecsDoc', objData.productSpecDoc);
	  fData.append('productMsdsDoc', objData.productMsdsDoc);
	  fData.append('TestMethodDoc', objData.TestMethodDoc);
    const items = objData.items.map(e => {
      return {
        paramId: e.paramId,
        paramValue: e.paramValue,
        paramUOMId: e.paramUOMId,
        testMethod: e.testMethod,
        specsRemarks: e.specsRemarks,

      };
  });

  fData.append('items', JSON.stringify(items));
  // check post data before api  calling in service
  console.log(fData);
  //return false;

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response.status_code == 201){
        this.msgService.addWarnMsg({ summary: this.pageName, detail: response.message });
        return false;
      }
      else if (response.result.status_code == 200) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

}
