import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { Producttype } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProducttypeService {

  public pageName = 'Product Type';
  public dataList = new Subject<Array<Producttype>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.ProducttypeApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<Producttype> = [];
          response.result.ClassList.map(element => {
            element.status = element.ProducttypeStatus + '' === '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }

  public addData(objData: Producttype) {
    const fData = new FormData();
    fData.append('ProducttypeId', objData.ProducttypeId + '');
	fData.append('ProducttypeCode', objData.ProducttypeCode + '');
	fData.append('ProducttypeDesc', objData.ProducttypeDesc + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  public updateData(objData: Producttype) {
    const fData = new FormData();
    fData.append('ProducttypeId', objData.ProducttypeId + '');
	fData.append('ProducttypeCode', objData.ProducttypeCode + '');
	fData.append('ProducttypeDesc', objData.ProducttypeDesc + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('ProducttypeId', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: Producttype) {
    const fData = new FormData();
    fData.append('stat', objData.status ? '1' : '0');
    fData.append('ProducttypeId', objData.ProducttypeId + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }
}
