import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { EquipmentSubType, EquipmentType } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class EquipmentSubTypeService {
  public pageName = 'Equipment Sub Type';
  public dataList = new Subject<Array<EquipmentSubType>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.equipmentSubTypeApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<EquipmentSubType> = [];
          response.result.Equipmentsubtype.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }

  public getEquipmentTypes(): Subject<Array<EquipmentType>> {
    const url = this.config.ipAddress + this.config.equipmentTypeApi[ApiActions.GetSingle];
    const dataArray: Array<EquipmentType> = [];
    const data = new Subject<Array<EquipmentType>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Equipmenttype.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return data;
  }

  public addData(objData: EquipmentSubType) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  private fillData(objData: EquipmentSubType) {
    const fData = new FormData();
    fData.append('equipmentSubTypeId', objData.equipmentSubTypeId + '');
    fData.append('equipmentTypeId', objData.equipmentTypeId + '');
    fData.append('equipmentSubTypeCode', objData.equipmentSubTypeCode);
    fData.append('equipmentSubTypeDesc', objData.equipmentSubTypeDesc);
    fData.append('status', objData.status ? '1' : '0');

    return fData;
  }

  public updateData(objData: EquipmentSubType) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('equipmentSubTypeId', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: EquipmentSubType) {
    const fData = new FormData();
    fData.append('stat', objData.status ? '1' : '0');
    fData.append('equipmentSubTypeId', objData.equipmentSubTypeId + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

}
