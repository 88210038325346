import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Salesenquiry,ProductUOM,ProductMaster,Customer,PackMaster } from 'src/app/model';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class SalesenquiryService {

    public pageName = 'Sale Enquiry';
    public dataList = new Subject<Array<Salesenquiry>>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.SalesenquiryApi[apiOption];
    }

    public getUOMCodes() {
        const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
        const data = new Subject<Array<ProductUOM>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.UnitsOfMeasurement;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }

    public getProductsData() {
        const resultList = new Subject<Array<ProductMaster>>();
        const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Customerorderproducts];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.productMasterList) {
                    const list = response.result.productMasterList;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return resultList;
    }

    public getCustomers() {
        const url = this.config.ipAddress + this.config.customerMasterApi[ApiActions.GetSingle];
        const data = new Subject<Array<Customer>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Customermaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }

    public getEnquiryTypedropdownData() {
        const url = this.config.ipAddress + this.config.SalesenquiryApi[ApiActions.Create];
        const data = new Subject<Array<any>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }

    public getDataRecords() {
        let resultList = new Array<Salesenquiry>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.SalesenquiryList) {
                    resultList = response.result.SalesenquiryList.map(e => {
                      
                            e.saleenq_date = new Date(e.saleenq_date);
                        
                        return e;
                    });
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
    }

    public updateData(objData: Salesenquiry) {
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Salesenquiry) {
        objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Salesenquiry) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append('saleenq_id', objData.saleenq_id + '');
        fData.append('cust_id', objData.customerId + '');
        fData.append('enqtype_id', objData.enqtype_id + '');
        fData.append('saleenq_code', objData.saleenq_code + '');
        fData.append('saleenq_date', this.util.getDate(objData.saleenq_date));

        const items = objData.items.map(e => {
            return {
                prodId: e.prod_id,
                prodSpecs: e.prodSpecs,
                orderQty: e.EnqQty,
                orderUom: e.uom_id,

            };
        });

        fData.append('items', JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response.status_code == 201){
                this.msgService.addWarnMsg({ summary: this.pageName, detail: response.status });
                responseMsg.next('fail');
              }
              else if (response.result.status_code == 200) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}

