import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { AdminMenu, ServiceActions } from 'src/app/model';
import { Subject } from 'rxjs';
import { ApiActions } from './util.service';
import { CommonDataService } from './common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AdminMenuService implements ServiceActions {
  public adminMenuList = new Subject<Array<AdminMenu>>();
  public pageName = 'Admin Menu';
  public menusFromDB: Array<any> = [];
  public menus = new Subject<Array<{ menu: string, subMenus: Array<any> }>>();
  public navmenus = new Subject<Array<{ menu: string, subMenus: Array<any> }>>();
  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
    private msService: MsgService,
		private msgSvc: MsgService,
		private router: Router
	
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.leftMenuApi[apiOption];
  }
  public getMenus() {
    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.Get], null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<AdminMenu> = [];
          // TODO: it's for just tmp code...
          // -------------------------------------------
          response.result.AdminMenu.map(element => {
            const temp = element as AdminMenu;
            dataArray.push(temp);
          });
          this.menusFromDB = dataArray;
          
          this.initMenus();
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }

  public getLeftNavmenu() {
    let dataArray = [];    
    dataArray.push( {label: 'Navigation',isTitle: true});

    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.getTable], null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.map(element => {
            dataArray.push(element);
          });
          this.navmenus.next(dataArray);
        }
      },
          err => {
            
        });
  }

  private initMenus() {
    let menus = [];
    // Menu Headers
    
    menus.push( {label: 'Navigation',isTitle: true},{label: 'Dashboard',icon: 'home',link: 'home'});
    const menuHeaders = this.menusFromDB.filter(m => m.menuParentId == '0');
    console.log(this.menusFromDB);
    for (const menu of menuHeaders) {
    
      const subMenus = this.menusFromDB.filter(m => m.menuParentId == menu.menuID);
     // console.log(subMenus);
      const menuItem = subMenus.map(m => {
        return { label: m.menuDesc, link: m.menuLink } ;
      });
      menus.push({ label: menu.menuDesc, icon: 'package',subItems: menuItem });
    }
      this.menus.next(menus);
  }
  public getDataRecords() {

    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.GetTasks]
      , null
      , { headers: undefined }).subscribe((response: any) => {
        if (response && response.result) {
          const adminLst = [];
          response.result.AdminMenu.forEach(element => {
            element.menuIsActive = element.menuIsActive == '1' ? true : false;
            adminLst.push(element);
          });
          this.adminMenuList.next(adminLst);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }

  public getcategories(): Subject<Array<AdminMenu>> {
    const url = this.config.ipAddress + this.config.leftMenuApi[ApiActions.GetSingle];
    const dataArray: Array<AdminMenu> = [];
    const data = new Subject<Array<AdminMenu>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.AdminMenu.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public addData(adminMenu: AdminMenu) {
    const fData = this.fillDataObj(adminMenu);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  private fillDataObj(objData: AdminMenu) {
    const fData = new FormData();
    fData.append('menuID', objData.menuID + '');
    fData.append('menuCode', objData.menuCode);
    fData.append('menuDesc', objData.menuDesc);
    // fData.append('menuIcon', objData.menuIcon);
    fData.append('menuLink', objData.menuLink);
    fData.append('menuParentId', objData.menuParentId + '');
    // fData.append('menuPosition', objData.menuPosition + '');
    // fData.append('menuType', objData.menuType + '');
    // fData.append('menuIsActive', objData.menuIsActive ? '1' : '0');
    return fData;
  }

  public updateData(adminMenu: AdminMenu) {
    const fData = this.fillDataObj(adminMenu);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(dataObj) {
    const responseMsg = new Subject<string>();
    alert('Method not implemented...');
    return responseMsg;
  }

  public updateStatus(objData: AdminMenu) {
    const fData = new FormData();
    fData.append('menuID', objData.menuID + '');
    fData.append('menuIsActive', objData.menuIsActive ? '1' : '0');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }
  
}
