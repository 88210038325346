import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { AdminMenu, ServiceActions } from 'src/app/model';
import { Subject } from 'rxjs';
import { ApiActions } from './util.service';
import { CommonDataService } from './common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public pageName = 'User Profile';
  public dataList = new Subject<Array<any>>();
  public MsgdataList = new Subject<Array<any>>();
  public TaskdataList = new Subject<Array<any>>();
  public ActivetydataList = new Subject<Array<any>>();
  public filesdataList = new Subject<Array<any>>();
  constructor( private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
    private msService: MsgService,
		private msgSvc: MsgService,
		private router: Router) { }

    private getUrl(apiOption: ApiActions) {
      return this.config.ipAddress + this.config.profiledata[apiOption];
    }

    public getUsersDataRecords() {
      const messageusersList = new Subject<any>();
      this.http.post(this.config.ipAddress + this.config.profiledata[ApiActions.Get]
        , null
        , { headers: undefined }).subscribe((response: any) => {
          if (response && response.result) {
            const adminLst = [];
            response.result.forEach(element => {
            //  element.status = element.status == '1' ? true : false;
              adminLst.push(element);
            });
           this.dataList.next(adminLst);
          }
        },
          err => {
              
          });
    }

    public getUserMessages(reciverid:number) {
      const messageusersList = new Subject<any>();
      const fData = new FormData();
      fData.append('reciverid', reciverid + '');
      this.http.post(this.config.ipAddress + this.config.profiledata[ApiActions.Update]
        , fData
        , { headers: undefined }).subscribe((response: any) => {
          if (response && response.result) {
            const adminLst = [];
            response.result.forEach(element => {
              adminLst.push(element);
            });
           this.MsgdataList.next(adminLst);
          }
        },
          err => {
               
          });
    }
    
    public addData(reciverid:number,message:string) {
      const fData = new FormData();
      fData.append('reciverid', reciverid + '');
      fData.append('message', message);
      return this.http.post(this.getUrl(ApiActions.Create),fData,{ headers: undefined });
    }

  // activety

    public getActivetyDataRecords() {
      const messageusersList = new Subject<any>();
      this.http.post(this.config.ipAddress + this.config.ActivityListApi[ApiActions.Get]
        , null
        , { headers: undefined }).subscribe((response: any) => {
          if (response && response.result.Notifications) {
            const adminLst = [];
            response.result.Notifications.forEach(element => {
            //  element.status = element.status == '1' ? true : false;
              adminLst.push(element);
            });
           this.ActivetydataList.next(adminLst);
          }
        },
          err => {
              
          });
    }
//tasks
    public getTasksDataRecords() {
      const messageusersList = new Subject<any>();
      this.http.post(this.config.ipAddress + this.config.profiledata[ApiActions.GetTasks]
        , null
        , { headers: undefined }).subscribe((response: any) => {
          if (response && response.result) {
            const adminLst = [];
            response.result.forEach(element => {
            //  element.status = element.status == '1' ? true : false;
              adminLst.push(element);
            });
           this.TaskdataList.next(adminLst);
          }
        },
          err => {
              
          });
    }

    public addTaskData(reciverid:any) {
      const fData = new FormData();
      fData.append('title', reciverid.title + '');
      fData.append('task_type', reciverid.task);
      fData.append('assign_to', reciverid.user_id + '');
      return this.http.post(this.getUrl(ApiActions.AddTask),fData,{ headers: undefined });
    }
    public updateTaskData(reciverid:any) {
      const fData = new FormData();
      fData.append('task_id', reciverid.id + '');
      fData.append('task_desc', reciverid.title + '');
      fData.append('task_type', reciverid.status);
      fData.append('assign_to', reciverid.user_id + '');
      fData.append('task_state', reciverid.status + '');
      return this.http.post(this.getUrl(ApiActions.EditTask),fData,{ headers: undefined });
    }
    public deleteTaskData(reciverid:number) {
      const fData = new FormData();
      fData.append('task_id', reciverid + '');
      return this.http.post(this.getUrl(ApiActions.DeleteTask),fData,{ headers: undefined });
    }
    public changeTaskStatus(reciverid:number,message:string) {
      const fData = new FormData();
      fData.append('reciverid', reciverid + '');
      fData.append('message', message);
      return this.http.post(this.getUrl(ApiActions.ChangeTaskStatus),fData,{ headers: undefined });
    }

//files

  public getFilesDataRecords() {
    const messageusersList = new Subject<any>();
    this.http.post(this.config.ipAddress + this.config.profiledata[ApiActions.GetFiless]
      , null
      , { headers: undefined }).subscribe((response: any) => {
        if (response && response.result) {
          const adminLst = [];
          response.result.forEach(element => {
          //  element.status = element.status == '1' ? true : false;
            adminLst.push(element);
          });
        this.filesdataList.next(adminLst);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }
  public addfileData(formData) {
    return this.http.post(this.getUrl(ApiActions.AddFiles),formData,{ headers: undefined });
  }
  public deleteFilesData(file_id:number) {
    const fData = new FormData();
    fData.append('file_id', file_id + '');
    return this.http.post(this.getUrl(ApiActions.DeleteFile),fData,{ headers: undefined });
  }
}
