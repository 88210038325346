import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Holidaylist } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class HolidaylistService {

  public pageName = 'Holiday List';
  public dataList = new Subject<Array<Holidaylist>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.HolidaylistApi[apiOption];
  }
  public getDataRecords() {
    let resultList = new Array<Holidaylist>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        console.log(response);
        if (response && response.result && response.result.HolidaylistList) {
          resultList = response.result.HolidaylistList.map(e => {

            e.holiday_date = new Date(e.h_date);

            return e;
          });
          this.dataList.next(resultList);
        }
      },
        err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });
  }

  public saveDraft(objData: Holidaylist) {
    return this.Save(this.getUrl(ApiActions.Create), objData);

  }
  public updateData(objData: Holidaylist) {
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  private Save(action: string, objData: Holidaylist) {
    console.log(objData);
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('holiday_id', objData.holiday_id + '');
    fData.append('holiday_desc', objData.holiday_desc);
    fData.append('status', objData.status+'');
    fData.append('holiday_optional', objData.holiday_optional+'');
    fData.append('holiday_date', this.util.getDate(objData.holiday_date));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
}
