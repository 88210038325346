import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { ProductGrade } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ProductGradeService {

  public pageName = 'Product Release Profile';
  public dataList = new Subject<Array<ProductGrade>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.productGradeApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<ProductGrade> = [];
          response.result.ProductGrade.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }

  public addData(objData: ProductGrade) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  private fillData(objData: ProductGrade) {
    const fData = new FormData();
    fData.append('gradeId', objData.gradeId + '');
    fData.append('gradeCode', objData.gradeCode);
    fData.append('gradeDesc', objData.gradeDesc);
    fData.append('status', objData.status ? '1' : '0');
    return fData;
  }

  public updateData(objData: ProductGrade) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);

  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('gradeid', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: ProductGrade) {
    const fData = new FormData();
    fData.append('stat', objData.status ? '1' : '0');
    fData.append('gradeid', objData.gradeId + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }
}
