import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { ApiActions,UtilService } from './util.service';
import { Subject } from 'rxjs';
import { AdminMenu } from 'src/app/model';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';

export interface Helpcontent {
  search_tag: string;
}
export const defaultHelpcontent = {
  search_tag: ''
};

export interface MenuInfo {
  name: string;
  link: string;
  isTitle:boolean;
  icon:string;
}

export interface MenuItem {
  menu: string;
  subMenus: Array<MenuInfo>;
}

export interface NotificationMsg {
  sno?: number;
  uuid: number;
  ntfDesc: string;
  ntfStatus: number;
  userId: number;
}

export interface ActivityMsg {
  date: Date;
  status: string;
}
export interface BatchListCalenderMsg {
  sno?: number;
  BatchId: number;
  BatchDesc: string;
  BatchStatus: number;
  BatchDate: Date;
  status: number;
}

export interface BatchDetailsMsg {
  sno?: number;
  BatchId: number;
  BatchDesc: string;
  BatchStatus: number;
  BatchDate: Date;
}

export const defaultCSS = 'app layout-fixed-header';
const hideMenuCSS = 'app layout-fixed-header layout-small-menu';


@Injectable({
  providedIn: 'root'
})
export class LeftMenuService {
  public menus = new Subject<Array<{ menu: string, subMenus: Array<MenuInfo> }>>();
  public menusFromDB: Array<AdminMenu> = [];
  public menuListForUserRoles = new Subject<Array<AdminMenu>>();
  public menuListForUserMenus = new Subject<Array<any>>();
  public editmenuListForUserMenus = new Subject<Array<AdminMenu>>();
  private menuCollapse = new Subject<string>();
  public dataObj: Helpcontent = defaultHelpcontent;
  constructor(
    private http: HttpClient,
    private config: AppConfigService,
		private msgSvc: MsgService,
		private router: Router,
    private util: UtilService
  ) { }


  public hideMenu() {
    this.menuCollapse.next(defaultCSS);
    setTimeout(() => {
      this.menuCollapse.next(hideMenuCSS);
    }, 100);
  }

  public toggleMenu() {
    return this.menuCollapse;
  }

  public getMenus() {
    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.Get], null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<AdminMenu> = [];
          // TODO: it's for just tmp code...
          // -------------------------------------------
          response.result.AdminMenu.map(element => {
            const temp = element as AdminMenu;
            dataArray.push(temp);
          });
          this.menusFromDB = dataArray;
        //  console.log(dataArray);
          this.initMenus();
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }
  public geteditMenus(selected_ids:string) {
    const editmenuListForUserMenus = new Subject<Array<any>>();
    const fData = new FormData();
    fData.append('selected_ids',selected_ids);
    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.Delete], fData , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const nCount = response.result.AdminMenu.map((e, indx) =>{           
            //  e['key'] = e.menuID;     
              return e;

          });
          editmenuListForUserMenus.next(nCount);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return editmenuListForUserMenus;
  }

  public clearMenus(user_id: any) {
  const clearmenuListForUserMenus = new Subject<Array<any>>();
  const fData = new FormData();
  fData.append('user_id', user_id);
  
  this.http.post(this.config.ipAddress + this.config.userMenusApi[ApiActions.Delete], fData, { headers: undefined })
    .subscribe(
      (response: any) => {
        console.log(response.result, "clear Menu data");
        if (response && response.result.userRoleDetails.status_code == 200) {
          const nCount = response.result.userMenus.map((e: any, indx: number) => {           
            //  e['key'] = e.menuID;     
            return e;
          });
          console.log(nCount); 
          clearmenuListForUserMenus.next(nCount);
          // this.router.navigate(['/pages/admin/user-menu']);
        }
      },
      (err) => {
        if (err.status === 401) {
          this.router.navigate(['/account/login']);
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
        } else {
          this.msgSvc.addErrorMsg({ summary: 'Error', detail: 'Something Went Wrong Please Try Again' });   
        }   
      }
    );

  return clearmenuListForUserMenus;
}

  
  public geteditRoleMenus(selected_ids:string) {
    const editmenuListForRoleMenus = new Subject<Array<any>>();
    const fData = new FormData();
    fData.append('selected_ids',selected_ids);
    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.Delete], fData , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const nCount = response.result.AdminMenu.map((e, indx) =>{           
            //  e['key'] = e.menuID;     
              return e;

          });
          editmenuListForRoleMenus.next(nCount);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return editmenuListForRoleMenus;
  }

  private initMenus() {
    let menus = [];
    // Menu Headers
    
   // menus.push( {label: 'Navigation',isTitle: true},{label: 'Dashboard',icon: 'home',link: 'home'});
    const menuHeaders = this.menusFromDB.filter(m => m.menuParentId == 0);
    for (const menu of menuHeaders) {
      const subMenus = this.menusFromDB.filter(m => m.menuParentId == menu.menuID);
      const menuItem = subMenus.map(m => {
        return { name: m.menuDesc, link: m.menuLink } as MenuInfo;
      });
      menus.push({ menu: menu.menuDesc, subMenus: menuItem });
    }
    
    menus = [];

    const menuItems = this.menusFromDB.map(m => {
      return { name: m.menuDesc, link: m.menuLink } as MenuInfo;
    });
    menus.push({ menu: 'Admin Menu', subMenus: menuItems });
 console.log('the menu items are:', menuItems);
    this.menus.next(menus);
  }

  

  public getMenuFromDBForUserRoles() {
    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.Get]
      , null
      , { headers: undefined }).subscribe((response: any) => {
        if (response && response.result && response.result.AdminMenu) {
          this.menuListForUserRoles.next(response.result.AdminMenu);
		  
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }
  public getMenuFromDBForUserMenus() {
    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.Get]
      , null
      , { headers: undefined }).subscribe((response: any) => {
        if (response && response.result) {
          this.menuListForUserMenus.next(response.result.AdminMenu);
		  
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
  }

  public getNotificationCount() {
    const returnData = new Subject<number>();
    let nCount = 0;
    this.http.post(this.config.ipAddress + this.config.getNotificationApi[ApiActions.GetSingle], null
      , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          nCount = Number(response.result.Notifications.nCount.toString());
          returnData.next(nCount);
        }
      },
          err => {
          
        });
    return returnData;
  }

  public getMessagesCount() {
    const returnData = new Subject<number>();
    let nCount = 0;
    this.http.post(this.config.ipAddress + this.config.getNotificationApi[ApiActions.Update], null
      , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.totalrecords) {
          nCount = Number(response.totalrecords.toString());
          returnData.next(nCount);
        }
      },
          err => { 
        });
    return returnData;
  }
  public getHeaderMessages() {
    const returnData = new Subject<Array<any>>();
    this.http.post(this.config.ipAddress + this.config.getNotificationApi[ApiActions.Delete], null , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.totalrecords) {
          const nCount = response.totalrecords;
          returnData.next(nCount);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return returnData;
  }

  public getDashboardOrders() {
    const DashboardOrders = new Subject<Array<any>>();
    this.http.post(this.config.ipAddress + this.config.getNotificationApi[ApiActions.GetTasks], null , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result.CustomerOrders) {
          const nCount = response.result.CustomerOrders;
          DashboardOrders.next(nCount);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return DashboardOrders;
  }
  public getDashboardCompletedcbu() {
    const DashboardOrders = new Subject<Array<any>>();
    this.http.post(this.config.ipAddress + this.config.getNotificationApi[ApiActions.AddTask], null , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result.CustomerOrders) {
          const nCount = response.result.CustomerOrders;
          DashboardOrders.next(nCount);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return DashboardOrders;
  }
  public getProductsstatus(selecteddate:Date) {
    const DashboardOrders = new Subject<Array<any>>();
    const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
    this.http.post(this.config.ipAddress + this.config.getCapacityPlanningDetailsApi[ApiActions.Update], fData , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result.CapacityPlanningDetails) {
          const nCount = response.result.CapacityPlanningDetails;
          DashboardOrders.next(nCount);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return DashboardOrders;
  }
  public getProductsExecutionstatus(selecteddate:Date) {
    const DashboardOrders = new Subject<Array<any>>();
    const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
    this.http.post(this.config.ipAddress + this.config.getCapacityPlanningDetailsApi[ApiActions.Create], fData , { headers: undefined })
      .subscribe((response: any) => {
        if (response.result && response.result.CapacityPlanningDetails) {
          const nCount = response.result.CapacityPlanningDetails;
          DashboardOrders.next(nCount);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return DashboardOrders;
  }
  public getEquipmentExecutionstatus(selecteddate:Date) {
    const DashboardOrders = new Subject<Array<any>>();
    const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
    this.http.post(this.config.ipAddress + this.config.getCapacityPlanningDetailsApi[ApiActions.GetSingle], fData , { headers: undefined })
      .subscribe((response: any) => {
        if (response.result && response.result.CapacityPlanningDetails) {
          const nCount = response.result.CapacityPlanningDetails;
          DashboardOrders.next(nCount);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return DashboardOrders;
  }
	public getBatchevents(selecteddate:Date) {
		const Batchevents = new Subject<Array<BatchListCalenderMsg>>();
    const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
		this.http.post(this.config.ipAddress + this.config.ActivityListApi[ApiActions.Create], fData, { headers: undefined }).subscribe((response: any) => {
			if (response && response.result) {
				const nCount = response.result.Notifications;
				Batchevents.next(nCount);
			}
		},
        err => {
			  this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
		return Batchevents;
	}

  
	public getBatchdetails(batchname:string) {
		const Batchedetails = new Subject<Array<BatchDetailsMsg>>();
		
		const frm = new FormData();
		frm.append('batchname', batchname + '');
		
		this.http.post(this.config.ipAddress + this.config.ActivityListApi[ApiActions.Update], frm, { headers: undefined }).subscribe((response: any) => {
			if (response && response.result) {
				const nCount = response.result.Notifications;
				Batchedetails.next(nCount);
			}else{

        this.msgSvc.addErrorMsg({ summary: 'Batch Details', detail: 'No Data for this Batch' }); 
      }
		},
        err => {
		 this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
		return Batchedetails;
	}

	public getActivityList() {
		const ActivityList = new Subject<Array<ActivityMsg>>();
		this.http.post(this.config.ipAddress + this.config.ActivityListApi[ApiActions.Get], null, { headers: undefined }).subscribe((response: any) => {
			if (response && response.result) {
				const nCount = response.result.Notifications;
				ActivityList.next(nCount);
			}
		},
        err => {
			  this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
		return ActivityList;
	}
  
  public getHelpContentsList() {
		const ActivityList = new Subject<Array<ActivityMsg>>();
		this.http.post(this.config.ipAddress + this.config.SharevideoApi[ApiActions.GetUserPermissions], null, { headers: undefined }).subscribe((response: any) => {
			if (response && response.result) {
				const nCount = response.result.Sharevideo;
				ActivityList.next(nCount);
			}
		},
        err => {
			  this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
		return ActivityList;
	}


  public getsearchhelpcontent(search_tag: string): Subject<Array<ActivityMsg>> {
    const url = this.config.ipAddress + this.config.SharevideoApi[ApiActions.Delete];
    const activityList: Array<ActivityMsg> = [];
    const data = new Subject<Array<ActivityMsg>>();
    const fData = new FormData();
    fData.append('search_tag', search_tag + '');
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Sharevideo.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            activityList.push(element);
          });
          data.next(activityList);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return data;
  }

  public getNotifications() {
    const returnData = new Subject<Array<NotificationMsg>>();
    this.http.post(this.config.ipAddress + this.config.getNotificationApi[ApiActions.Get], null
      , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const notifications = response.result.Notifications;
          returnData.next(notifications);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });

    return returnData;
  }

  public updateNotification(msg: NotificationMsg) {
    const returnData = new Subject<boolean>();

    const frm = new FormData();
    frm.append('uuid', msg.uuid + '');
    this.http.post(this.config.ipAddress + this.config.getNotificationApi[ApiActions.Reset],
      frm,
      { headers: undefined }).subscribe((response: any) => {
        if (response && response.result) {
          returnData.next(true);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return returnData;
  }
  public getRawmaterialsvaluesList(selecteddate:Date) {
    const Rawmaterialsvalues = new Subject<Array<BatchListCalenderMsg>>();
    const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
		this.http.post(this.config.ipAddress + this.config.dashboardserviceApi[ApiActions.Get], fData, { headers: undefined }).subscribe((response: any) => {
			if (response && response.result) {
				const nCount = response.result.rawmaterialsvalues;
				Rawmaterialsvalues.next(nCount);
			}
		},
        err => {
			  this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
		return Rawmaterialsvalues;
  }
}
