import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MetismenuAngularModule } from "@metismenu/angular";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNGModule } from './prime-module';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ContextMenuModule } from 'primeng/contextmenu';
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgbDatepickerModule, NgbDropdownModule, NgbAlertModule, NgbToastModule, NgbButtonsModule, NgbTabsetModule, NgbAccordionModule, NgbModalModule, NgbProgressbarModule, NgbPopoverModule, NgbTooltipModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AppServiceProvider, BusyCursorService} from 'src/app/service';
import { HttpDataService } from './service/common/http-data.service';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { TreeModule } from 'primeng/tree';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ListboxModule } from 'primeng/listbox';
import { InputMaskModule } from 'primeng/inputmask';
import { MultiSelectModule } from 'primeng/multiselect';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ImageModule } from 'primeng/image';
import { TagModule } from 'primeng/tag';
import { UIModule } from 'src/app/shared/ui/ui.module';
import {DividerModule} from 'primeng/divider';
import { NgxChartsModule }from '@swimlane/ngx-charts';
import {EditorModule} from 'primeng/editor';

//common
import { CityComponent } from 'src/app/pages/common/city/city.component';
import { StateComponent } from 'src/app/pages/common/state/state.component';
import { CountryComponent } from 'src/app/pages/common/country/country.component';
import { DepartmentComponent } from 'src/app/pages/common/department/department.component';
import { DesignationComponent } from 'src/app/pages/common/designation/designation.component';
import { EmployeetypeComponent } from 'src/app/pages/common/employeetype/employeetype.component';
import { UomComponent } from 'src/app/pages/common/uom/uom.component';
import { DataimportComponent } from 'src/app/pages/common/dataimport/dataimport.component';

import { SharevideoComponent } from 'src/app/pages/common/sharevideo/sharevideo.component';

//admin
import { UserComponent } from 'src/app/pages/admin/user/user.component';
import { UserRolesComponent } from 'src/app/pages/admin/user-roles/user-roles.component';
import { UserMenusComponent } from 'src/app/pages/admin/user-menus/user-menus.component';
import { AdminMenuComponent } from 'src/app/pages/admin/admin-menu/admin-menu.component';
//master
import { ProductMasterComponent } from 'src/app/pages/master/product-master/product-master.component';
import { ProductSpecificationComponent } from 'src/app/pages/master/product-specification/product-specification.component';
import { ItemSpecificationComponent } from 'src/app/pages/master/item-specification/item-specification.component';
import { BatchIdentityComponent } from 'src/app/pages/master/batch-identity/batch-identity.component';
import { ItemMasterComponent } from 'src/app/pages/master/item-master/item-master.component';
import { IntermediatesComponent } from 'src/app/pages/master/intermediates/intermediates.component';
import { EquipmentMasterComponent } from 'src/app/pages/master/equipment-master/equipment-master.component';
import { CustomerMasterComponent } from 'src/app/pages/master/customer-master/customer-master.component';
import { UnitMasterComponent } from 'src/app/pages/master/unit-master/unit-master.component';
import { UnitLineMasterComponent } from 'src/app/pages/master/unit-line-master/unit-line-master.component';
import { MasterFormulaComponent } from 'src/app/pages/master/master-formula/master-formula.component';
import { ProcessConfigComponent } from 'src/app/pages/master/process-config/process-config.component';
// sub-master
import { FieldsComponent } from 'src/app/pages/submaster/fields/fields.component';
import { FieldSeperatorComponent } from 'src/app/pages/submaster/field-seperator/field-seperator.component';
import { ProductCategoryComponent } from 'src/app/pages/submaster/product-category/product-category.component';
import { ProductPhysicalFormComponent } from 'src/app/pages/submaster/product-physical-form/product-physical-form.component';
import { ProductGradeComponent } from 'src/app/pages/submaster/product-grade/product-grade.component';
import { ProductPharmacopieaComponent } from 'src/app/pages/submaster/product-pharmacopiea/product-pharmacopiea.component';
import { ProductParameterComponent } from 'src/app/pages/submaster/product-parameter/product-parameter.component';
import { ProducttypeComponent } from 'src/app/pages/submaster/producttype/producttype.component';
import { ItemFormComponent } from 'src/app/pages/submaster/item-form/item-form.component';
import { ItemCategoryComponent } from 'src/app/pages/submaster/item-category/item-category.component';
import { ItemClassComponent } from 'src/app/pages/submaster/item-class/item-class.component';
import { ItemGradeComponent } from 'src/app/pages/submaster/item-grade/item-grade.component';
import { ItemtypeComponent } from 'src/app/pages/submaster/itemtype/itemtype.component';
import { EquipmentTypeComponent } from 'src/app/pages/submaster/equipment-type/equipment-type.component';
import { EquipmentSubTypeComponent } from 'src/app/pages/submaster/equipment-sub-type/equipment-sub-type.component';
import { LineMasterComponent } from 'src/app/pages/submaster/line-master/line-master.component';
import { CustomerTypeComponent } from 'src/app/pages/submaster/customer-type/customer-type.component';
import { ProcessConfigStepsComponent } from 'src/app/pages/submaster/process-config-steps/process-config-steps.component';
import { ProcessConfigParamsComponent } from 'src/app/pages/submaster/process-config-params/process-config-params.component';
import { ItemParameterComponent } from 'src/app/pages/submaster/item-parameter/item-parameter.component';
// production
import { UnitWiseShiftTimingComponent } from 'src/app/pages/scheduling/unit-wise-shift-timing/unit-wise-shift-timing.component';
import { EquipmentOperatingTimingComponent } from 'src/app/pages/scheduling/equipment-operating-timing/equipment-operating-timing.component';
import { EquipmentLineClearanceTimingsComponent } from 'src/app/pages/scheduling/equipment-line-clearance-timings/equipment-line-clearance-timings.component';
import { CustomerOrderComponent } from 'src/app/pages/scheduling/customer-order/customer-order.component';
import { InternalOrderComponent } from 'src/app/pages/scheduling/internal-order/internal-order.component';
import { SalesForecastComponent } from 'src/app/pages/scheduling/sales-forecast/sales-forecast.component';
import { LotPriorityPlanningComponent } from 'src/app/pages/scheduling/lot-priority-planning/lot-priority-planning.component';
import { ProductionOrderSequenceGeneratorComponent } from 'src/app/pages/scheduling/production-order-sequence-generator/production-order-sequence-generator.component';
import { ProcessSchedulerComponent } from 'src/app/pages/scheduling/process-scheduler/process-scheduler.component';
import { PlancomparisonComponent } from 'src/app/pages/scheduling/plancomparison/plancomparison.component';
import { DailyproductionlogComponent } from 'src/app/pages/scheduling/dailyproductionlog/dailyproductionlog.component';
import { MaterialtransfernoteComponent } from 'src/app/pages/scheduling/materialtransfernote/materialtransfernote.component';
import { DispatchQuantityComponent } from 'src/app/pages/scheduling/dispatch-quantity/dispatch-quantity.component';
//import { TopsearchComponent } from 'src/app/pages/topsearch/topsearch.component';

import { ProductinventoryComponent } from 'src/app/pages/inventory/products-inventory/products-inventory.component';
import { RawmaterialsinventoryComponent } from 'src/app/pages/inventory/raw-materials-inventory/raw-materials-inventory.component';
import { ShiftTimingsComponent } from 'src/app/pages/submaster/shift-timings/shift-timings.component';


import { ParameterwiseutilityComponent } from 'src/app/pages/productioncontrol/parameterwiseutility/parameterwiseutility.component';
import { EquipmentplanningComponent } from 'src/app/pages/productioncontrol/equipmentplanning/equipmentplanning.component';
import { UtilityservicesplanningComponent } from 'src/app/pages/productioncontrol/utilityservicesplanning/utilityservicesplanning.component';
import { RawmaterialsplanningComponent } from 'src/app/pages/productioncontrol/rawmaterialsplanning/rawmaterialsplanning.component';
import { AssignbatchestochemistComponent } from 'src/app/pages/productioncontrol/assignbatchestochemist/assignbatchestochemist.component';
import { ProductionreadinessComponent } from 'src/app/pages/productioncontrol/productionreadiness/productionreadiness.component';

//sales
import { SalesquatationsagainstenquiryComponent } from 'src/app/pages/sales/salesquatationsagainstenquiry/salesquatationsagainstenquiry.component';
import { SalesordersComponent } from 'src/app/pages/sales/salesorders/salesorders.component';
import { GoodsissueagainestsalesordersComponent } from 'src/app/pages/sales/goodsissueagainestsalesorders/goodsissueagainestsalesorders.component';
import { SalesretunrsComponent } from 'src/app/pages/sales/salesretunrs/salesretunrs.component';
import { SalesenquiryComponent } from 'src/app/pages/sales/salesenquiry/salesenquiry.component';

//Purchases
import { PurchasesquatationsagainstenquiryComponent } from 'src/app/pages/purchases/purchasesquatationsagainstenquiry/purchasesquatationsagainstenquiry.component';
import { PurchasesordersComponent } from 'src/app/pages/purchases/purchasesorders/purchasesorders.component';
import { GoodsreceivableComponent } from 'src/app/pages/purchases/goodsreceivable/goodsreceivable.component';
import { PurchasesretunrsComponent } from 'src/app/pages/purchases/purchasesretunrs/purchasesretunrs.component';
import { PurchasesenquiryComponent } from 'src/app/pages/purchases/purchasesenquiry/purchasesenquiry.component';
import { SupplierlistComponent } from 'src/app/pages/purchases/supplierlist/supplierlist.component';

//inventary

import { PostockupdateComponent } from 'src/app/pages/inventory/po-stock-update/po-stock-update.component';
import { SostockupdatesComponent } from 'src/app/pages/inventory/so-stock-update/so-stock-update.component';
import { MaterialsrequestsComponent } from 'src/app/pages/inventory/materials-requests/materials-requests.component';
import { IndentissuesComponent } from 'src/app/pages/inventory/indent-issues/indent-issues.component';
import { IndentraisedComponent } from 'src/app/pages/inventory/indent-raised/indent-raised.component';
import { InventoryquarantineComponent } from 'src/app/pages/inventory/inventoryquarantine/inventoryquarantine.component';
import { ProductinventoryquarantineComponent } from 'src/app/pages/inventory/productinventoryquarantine/productinventoryquarantine.component';

//humanresource
import { EmployeedetailsComponent } from 'src/app/pages/humanresource/employeedetails/employeedetails.component';
import { PaycomponentsComponent } from 'src/app/pages/humanresource/paycomponents/paycomponents.component';
import { LeavecomponentsComponent } from 'src/app/pages/humanresource/leavecomponents/leavecomponents.component';
import { PaystructureComponent } from 'src/app/pages/humanresource/paystructure/paystructure.component';
import { LeavestructureComponent } from 'src/app/pages/humanresource/leavestructure/leavestructure.component';
import { HolidaylistComponent } from 'src/app/pages/humanresource/holidaylist/holidaylist.component';
import { LeaveComponent } from 'src/app/pages/humanresource/leave/leave.component';
import { OvertimesComponent } from 'src/app/pages/humanresource/overtimes/overtimes.component';
import { AttendanceComponent } from 'src/app/pages/humanresource/attendance/attendance.component';
import { GeneratepayslipComponent } from 'src/app/pages/humanresource/generatepayslip/generatepayslip.component';
import { EmployeeshiftsComponent } from 'src/app/pages/humanresource/employee-shifts/employee-shifts.component';
// qualitycontrol
import { QualitytestsComponent } from 'src/app/pages/qualitycontrol/qualitytests/qualitytests.component';
import { ProductanalysisComponent } from 'src/app/pages/qualitycontrol/productanalysis/productanalysis.component';
import { InprocessanalysisComponent } from 'src/app/pages/qualitycontrol/inprocessanalysis/inprocessanalysis.component';
import { inprocessqualitytestComponent } from 'src/app/pages/qualitycontrol/inprocessqualitytest/inprocessqualitytest.component';
import { ItemanalysisComponent } from 'src/app/pages/qualitycontrol/itemanalysis/itemanalysis.component';
import { ProdqualitytestComponent } from 'src/app/pages/qualitycontrol/prodqualitytest/prodqualitytest.component';
import { CsvService} from 'src/app/service';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { QRCodeModule } from 'angularx-qrcode';


@NgModule({
  declarations: [
    AppComponent, UserComponent, UserRolesComponent, UserMenusComponent, AdminMenuComponent, ProductMasterComponent, ProductSpecificationComponent, BatchIdentityComponent, ItemMasterComponent, IntermediatesComponent, EquipmentMasterComponent, CustomerMasterComponent, UnitMasterComponent, UnitLineMasterComponent, MasterFormulaComponent, ProcessConfigComponent, FieldsComponent, FieldSeperatorComponent, ProductCategoryComponent, ProductPhysicalFormComponent, ProductGradeComponent, ProductPharmacopieaComponent, ProductParameterComponent,ItemSpecificationComponent, ProducttypeComponent, ItemFormComponent, ItemCategoryComponent, ItemClassComponent, ItemGradeComponent, ItemtypeComponent, EquipmentTypeComponent, EquipmentSubTypeComponent, LineMasterComponent, CustomerTypeComponent, ProcessConfigStepsComponent, ProcessConfigParamsComponent, UnitWiseShiftTimingComponent, EquipmentOperatingTimingComponent, EquipmentLineClearanceTimingsComponent, CustomerOrderComponent, InternalOrderComponent, SalesForecastComponent, LotPriorityPlanningComponent, ProductionOrderSequenceGeneratorComponent, ProcessSchedulerComponent, PlancomparisonComponent, DailyproductionlogComponent,MaterialtransfernoteComponent, DispatchQuantityComponent, ProductinventoryComponent, RawmaterialsinventoryComponent, ShiftTimingsComponent, ParameterwiseutilityComponent, EquipmentplanningComponent, UtilityservicesplanningComponent, RawmaterialsplanningComponent, AssignbatchestochemistComponent, ProductionreadinessComponent
    , SalesquatationsagainstenquiryComponent, SalesordersComponent, GoodsissueagainestsalesordersComponent, SalesretunrsComponent, SalesenquiryComponent, PurchasesquatationsagainstenquiryComponent, PurchasesordersComponent, GoodsreceivableComponent, PurchasesretunrsComponent, PurchasesenquiryComponent, SupplierlistComponent, PostockupdateComponent, SostockupdatesComponent, MaterialsrequestsComponent,IndentissuesComponent,IndentraisedComponent, CityComponent, StateComponent, CountryComponent, DepartmentComponent, UomComponent, EmployeedetailsComponent, PaycomponentsComponent, LeavecomponentsComponent, PaystructureComponent, LeavestructureComponent, HolidaylistComponent, LeaveComponent, OvertimesComponent, AttendanceComponent, GeneratepayslipComponent, DesignationComponent, EmployeetypeComponent, EmployeeshiftsComponent, InventoryquarantineComponent, ProductinventoryquarantineComponent, QualitytestsComponent, ProductanalysisComponent, InprocessanalysisComponent, inprocessqualitytestComponent, ProdqualitytestComponent, ItemanalysisComponent,DataimportComponent, ItemParameterComponent, SharevideoComponent],

  imports: [
    BrowserModule,
    CommonModule,
    MetismenuAngularModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    DropzoneModule,
    AppRoutingModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbAlertModule,
    NgbToastModule,
    NgbTabsetModule,
    NgbButtonsModule,
    NgbNavModule,
    SplitButtonModule,
    InputNumberModule,
    NgbAccordionModule,
    ToastModule,
    TreeModule,
    InputMaskModule,
    ContextMenuModule,
    SelectButtonModule,
    ListboxModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbTooltipModule,
    PrimeNGModule,
    TableModule,
    DropdownModule,
    ButtonModule,
    MultiSelectModule,
    PickListModule,
    RadioButtonModule,
    ProgressBarModule,
    AvatarModule,
    AvatarGroupModule,
    ImageModule,
    TagModule,
    DividerModule,
    NgxChartsModule,
    UIModule,
    EditorModule,
    DialogModule,
    AccordionModule,
    QRCodeModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [BusyCursorService,
    MessageService,
    AppServiceProvider,
    DatePipe,
    CsvService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpDataService, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
