import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Materialsrequests,ProductUOM,ItemMaster,Supplierlist,PackMaster } from 'src/app/model';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class MaterialsrequestsService {

    public pageName = 'Materials Requests';
    public dataList = new Subject<Array<Materialsrequests>>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.MaterialsrequestsApi[apiOption];
    }

    public getUOMCodes() {
        const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
        const data = new Subject<Array<ProductUOM>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.UnitsOfMeasurement;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }

    public getItemsData() {
        const resultList = new Subject<Array<ItemMaster>>();
        const url = this.config.ipAddress + this.config.itemMasterApi[ApiActions.GetSingle];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.ItemMasterList) {
                    const list = response.result.ItemMasterList;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return resultList;
    }

    public getCustomers() {
        const url = this.config.ipAddress + this.config.SupplierMasterApi[ApiActions.Get];
        const data = new Subject<Array<Supplierlist>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Supplierlistmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }

    public getEnquiryTypedropdownData() {
        const url = this.config.ipAddress + this.config.MaterialsrequestsApi[ApiActions.Create];
        const data = new Subject<Array<any>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }

    public getDataRecords() {
        let resultList = new Array<Materialsrequests>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.MaterialsrequestsList) {
                    resultList = response.result.MaterialsrequestsList.map(e => {
                      
                            e.ReqDate = new Date(e.ReqDate);
                        
                        return e;
                    });
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
    }

    public updateData(objData: Materialsrequests) {
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Materialsrequests) {
        objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Materialsrequests) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append('purenq_id', objData.req_id + '');
        fData.append('supp_id', objData.dept_id + '');
        fData.append('enqtype_id', objData.enqtype_id + '');
        fData.append('EnqCode', objData.ReqCode + '');
        fData.append('EnqDate', this.util.getDate(objData.ReqDate));

        const items = objData.items.map(e => {
            return {
                prodId: e.item_id,
                prodSpecs: e.ItemSpecs,
                orderQty: e.ReqQty,
                orderUom: e.uom_id,

            };
        });

        fData.append('items', JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}

