import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LotPriorityPlanning } from 'src/app/model';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { ApiActions, UtilService } from '../common/util.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class LotPriorityPlanningService {

  public pageName = 'LOT Priority Planning';
  public dataList = new Subject<Array<LotPriorityPlanning>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.lotPriorityPlanningApi[apiOption];
  }

  public getDataRecords(fromDate: Date, toDate: Date,plan_id:number) {
    let resultList = new Array<LotPriorityPlanning>();
    const fData = new FormData();
    fData.append('fromDate', this.util.getDate(fromDate));
    fData.append('toDate', this.util.getDate(toDate));
    fData.append('plan_id', plan_id+'');
    this.http.post(this.getUrl(ApiActions.Get), fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result.LOTPlanningOrders) {
          resultList = response.result.LOTPlanningOrders.map(e => {
            e.custPODate = new Date(e.custPODate);
            e.deliveryDate = new Date(e.deliveryDate);

            e.prodBatchesNumber = Math.ceil(Number(e.numberOfProdBatches));
            // e.numberOfLots = e.numberOfLots ?  e.numberOfLots : 1;
            // e.transitTime = e.transitTime ? e.transitTime : 1;
            return e;
          });
          this.dataList.next(resultList);
        }
        else{
          this.msgService.addWarnMsg({ summary: this.pageName, detail: response.result.status });
          
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
  }

  public updateData(fromDate: Date, toDate: Date, objList: Array<LotPriorityPlanning>) {
    return this.Save(fromDate, toDate, this.getUrl(ApiActions.Update), objList, this.util.getSaveStatus());
  }

  public saveDraft(fromDate: Date, toDate: Date, objList: Array<LotPriorityPlanning>) {
    return this.Save(fromDate, toDate, this.getUrl(ApiActions.DraftSave), objList, this.util.getDraftStatus());
  }

  private Save(fromDate: Date, toDate: Date, action: string, objList: Array<LotPriorityPlanning>, statusVal: number) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('fromDate', this.util.getDate(fromDate));
    fData.append('toDate', this.util.getDate(toDate));
    const data = objList.map(obj => {
      return {
        customerPurchaseOrder: obj.customerPurchaseOrder,
        customerOrderId: obj.customerOrderId,
        custCode: obj.custCode,
        priorityNum: obj.priorityNum,
        prodId: obj.prodId,
		plan_id: obj.plan_id,
        orderQuantity: obj.orderQuantity,
        transitTime: obj.transitTime,
        noOfLots: obj.numberOfLots,
        dispatchDate: obj.dispatchDate,
        status: statusVal
      };
    });

    fData.append('data', JSON.stringify(data));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

}
