import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject } from 'rxjs';
import { Dailyproductionlog,Productstepslog } from 'src/app/model';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { ApiActions, UtilService } from '../common/util.service';
import { CommonDataService } from '../common/common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class DailyproductionlogService {
	public pageName = 'Daily Production Log';
   public dataList = new Subject<Array<Dailyproductionlog>>();
  // public StepsdataList = new Subject<Array<Productstepslog>>();
  

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.DailyproductionlogApi[apiOption];
  }

  public getDataRecords(fromDate: Date, toDate: Date) {
    let resultList = new Array<Dailyproductionlog>();
    const fData = new FormData();
    fData.append('fromDate', this.util.getDate(fromDate));
    fData.append('toDate', this.util.getDate(toDate));
    this.http.post(this.getUrl(ApiActions.Get), fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.LOTPlanningOrders) {
          resultList = response.result.LOTPlanningOrders.map(e => {
            e.run_end_date = new Date(e.run_end_date);
            return e;
          });
          this.dataList.next(resultList);
        }
        else {
          this.msgService.addWarnMsg({ summary: this.pageName, detail: 'No Plan exisits Between Dates..' });
          return false;        
        } 
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });    
        });
  }
  public getStepsRecords(pcId: number,bnId: number,plnId:number) {
  //  const resultList:  Array<Dailyproductionlog>=[];
 
   //const StepsdataList = new Subject<Array<Productstepslog>>();
     const dataArray: Array<Productstepslog> = [];
    const data = new BehaviorSubject<Array<Productstepslog>>([]);
    const fData = new FormData();
    fData.append('fromDate', pcId+'');
    fData.append('toDate', bnId+'');
    fData.append('plan_id', plnId+'');
    this.http.post(this.getUrl(ApiActions.GetSingle), fData, { headers: undefined })  .subscribe((response: any) => {
        if (response && response.result) {
          response.result.LOTPlanningOrders.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
    return data;
   }
   
    public getReasionsRecords() {
  //  const resultList:  Array<Dailyproductionlog>=[];
 
   //const StepsdataList = new Subject<Array<Productstepslog>>();
     const dataArray: Array<Productstepslog> = [];
    const data = new BehaviorSubject<Array<Productstepslog>>([]);
 
		this.http.post(this.getUrl(ApiActions.getTable), null, { headers: undefined })  .subscribe((response: any) => {
			if (response && response.result) {
				response.result.LOTPlanningOrders.map(element => {
					element.status = element.status == '1' ? true : false;
					dataArray.push(element);
				});
				data.next(dataArray);
			}
		},
          err => {
          if (err.status === 401) {
            this.router.navigate(['/account/login']);
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again' }); 
          }else{
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
          }   
        });
		return data;
	}
  public updateData(fromDate: number, toDate: number, objList: Array<any>) {
    return this.Save(fromDate, toDate, this.getUrl(ApiActions.Update), objList, this.util.getSaveStatus());
  }

  // public saveDraft(fromDate: Date, toDate: Date, objList: Array<Dailyproductionlog>) {
    // return this.Save(fromDate, toDate, this.getUrl(ApiActions.DraftSave), objList, this.util.getDraftStatus());
  // }

  private Save(fromDate: number, toDate: number, action: string, objList, statusVal: number) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('fromDate', fromDate+'');
    fData.append('toDate', toDate+'');
	fData.append('s_id', objList.s_id+'');
    fData.append('step_running_status', objList.step_running_status+'');
	fData.append('actual_end_date', this.util.getDateTimeFormat(objList.actual_end_date)+'');
	fData.append('reason_for_delay', objList.delay_id+'');
	fData.append('step_qty', objList.step_qty+'');
	fData.append('QCYesNo', objList.QCYesNo+'');
	
    const data = objList;

    fData.append('data', JSON.stringify(data));
console.log(data);
    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
  public updateStatus(s_id: number, batch_hold: number, batch_comment: string,prod_id:number,batch_num:string) {
    const fData = new FormData();
    fData.append('batch_num', batch_num+'');
    fData.append('prod_id', prod_id+'');
    fData.append('batch_hold', batch_hold+'');
    fData.append('s_id', s_id + '');
    fData.append('batch_comment', batch_comment);
    console.log(fData);
   // return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
   return this.http.post( this.getUrl(ApiActions.Delete), fData);
  }

public updateobserveationsData(objList: Array<any>) {
	return this.Saveobserveations(this.getUrl(ApiActions.SaveobserveTable),objList);
}
  
  
private Saveobserveations(action,objList: Array<Productstepslog>) {
    const responseMsg = new Subject<string>();

   // const data = objList;
	console.log(objList);
	//JSON.stringify(objList)
	
	
	
	//const data = [];
const fData = new FormData();
    fData.append('data', JSON.stringify(objList));
	
	//action = ;
    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
}


}
