import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Leavecomponents } from 'src/app/model/';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class LeavecomponentsService {

  public pageName = 'Leave Components';
  public dataList = new Subject<Array<Leavecomponents>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.LeavecomponentsApi[apiOption];
  }
  public getDataRecords() {
    let resultList = new Array<Leavecomponents>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        console.log(response);
        if (response && response.result && response.result.LeavecomponentsList) {
          resultList = response.result.LeavecomponentsList;
          this.dataList.next(resultList);
        }
      },
        err => {
          //this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });


  }

  public saveDraft(objData: Leavecomponents) {
    return this.Save(this.getUrl(ApiActions.Create), objData);

  }
  public updateData(objData: Leavecomponents) {
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  private Save(action: string, objData: Leavecomponents) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('leavecomp_id', objData.leavecomp_id + '');
    fData.append('leavecomp_code', objData.leavecomp_code);
    fData.append('leavecomp_name', objData.leavecomp_name);

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
}
