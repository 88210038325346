import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Salesretunrs, ProductUOM, ProductMaster, Customer } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class SalesretunrsService {

  public pageName = 'Sale Returns';
  public dataList = new Subject<Array<Salesretunrs>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.SalesretunrsApi[apiOption];
  }

  public getUOMCodes() {
    const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
    const data = new Subject<Array<ProductUOM>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.UnitsOfMeasurement;
          data.next(result);
        }
      },
        err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });
    return data;
  }

  public getProductsData() {
    const resultList = new Subject<Array<ProductMaster>>();
    const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Customerorderproducts];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
          const list = response.result.productMasterList;
          resultList.next(list);
        }
      },
        err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });
    return resultList;
  }

  public getCustomers() {
    const url = this.config.ipAddress + this.config.customerMasterApi[ApiActions.Get];
    const data = new Subject<Array<Customer>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.Customermaster;
          data.next(result);
        }
      },
        err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });
    return data;
  }
  public getCUrrancyList(): Subject<Array<any>> {
    const url = this.config.ipAddress + this.config.currencyserviceApi[ApiActions.Get];
    const countrylist: Array<any> = [];
    const result = new Subject<Array<any>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Currency.map(element => {
            // element.status = Number(element.recStatus) === 1 ? true : false;
            countrylist.push(element);
          });
          result.next(countrylist);
        }
      },
        err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });
    return result;
  }
  public getEnquiryTypedropdownData() {
    const url = this.config.ipAddress + this.config.SalesretunrsApi[ApiActions.Create];
    const data = new Subject<Array<any>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.Packmaster;
          data.next(result);
        }
      },
        err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });
    return data;
  }

  public getDataRecords() {
    let resultList = new Array<Salesretunrs>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.SalesretunrsList) {
          resultList = response.result.SalesretunrsList.map(e => {

            e.QuotDate = new Date(e.QuotDate);
            e.OrdDate = new Date(e.OrdDate);
            e.ReturnDate = new Date(e.ReturnDate);

            return e;
          });
          this.dataList.next(resultList);
        }
      },
        err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });
  }

  public updateData(objData: Salesretunrs) {
    console.log(objData);
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: Salesretunrs) {
    objData.status = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: Salesretunrs) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();

    fData.append('salereturn_id', objData.salereturn_id + '');
    fData.append('saleissued_id', objData.saleissued_id + '');
    fData.append('ReturnCode', objData.ReturnCode + '');
    fData.append('ReturnDate', this.util.getDate(objData.ReturnDate));

    const items = objData.items.map(e => {
      return {
        salereturndtl_id: e.salereturndtl_id,
        prodId: e.prod_id,
        prodSpecs: e.prodSpecs,
        return_qty: e.ReturnQty,
        orderUom: e.quot_uom,

      };
    });

    fData.append('items', JSON.stringify(items));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
}
