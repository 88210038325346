import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Purchasesretunrs, ProductUOM, ProductMaster, Customer } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class PurchasesretunrsService {

  public pageName = 'Purchase Returns';
  public dataList = new Subject<Array<Purchasesretunrs>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.PurchasesretunrsApi[apiOption];
  }
  public getDataRecords() {
    let resultList = new Array<Purchasesretunrs>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.PurchasesretunrsList) {
          resultList = response.result.PurchasesretunrsList.map(e => {

            e.QuotDate = new Date(e.QuotDate);
            e.OrdDate = new Date(e.OrdDate);
            e.ReturnDate = new Date(e.ReturnDate);

            return e;
          });
          this.dataList.next(resultList);
        }
      },
        err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
        });
  }
  public updateData(objData: Purchasesretunrs) {
    console.log(objData);
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
}

public saveDraft(objData: Purchasesretunrs) {
    objData.status = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
}

private Save(action: string, objData: Purchasesretunrs) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();

    fData.append("purreceive_id", objData.purreceive_id + "");
    fData.append("purreturn_id", objData.purreturn_id + "");
    fData.append("purenq_id", objData.purenq_id + "");
    fData.append("supp_id", objData.supp_id + "");
    fData.append('ReturnCode', objData.ReturnCode + '');
    fData.append('ReturnDate', this.util.getDate(objData.ReturnDate));

    const items = objData.items.map((e) => {
        return {
            purreturndtl_id: e.purreturndtl_id,
            prodId: e.item_id,
            prodSpecs: e.ItemSpecs,
            orderUom: e.uom_id,
            ReturnQty: e.ReturnQty,
            QuotPrice: e.QuotPrice,
            currency_id: e.currency_id,
            OrdQty: e.OrdQty,
        };
    });

    fData.append('items', JSON.stringify(items));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
        if (response) {
            this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
            responseMsg.next('');
        }
    },
        err => {
            responseMsg.next(err);
        });

    return responseMsg;
}
}
