<!-- Right Sidebar -->
<div class="right-bar" (clickOutside)="hide()" [excludeBeforeClick]="true" [exclude]="'.right-bar-toggle'">
  <div class="rightbar-title">
    <a href="javascript:void(0);" class="right-bar-toggle float-right" (click)="hide();">
      <i data-feather="x-circle" appFeatherIcon></i>
    </a>
    <h5 class="m-0">Help Videos</h5>
  </div>
  <div class="rightbar-title">
  <input type="text" name="search_tag"  [(ngModel)]='dataObj.search_tag'class="form-control" placeholder="Search Here" >
  <!-- <button name="saveBtn" type="button" pButton icon="pi pi-search" (click)="onProductChange()"
   label="Save"></button> -->
   <i class="pi pi-search" (click)="onProductChange()"></i>
</div>
  <div class="card flex justify-content-center">
    <p-accordion [activeIndex]="0">
        <p-accordionTab header="{{prodItem.hcont_name }}" *ngFor="let prodItem of activityList">
            <p [innerHTML]="prodItem.hcont_desc">{{prodItem.hcont_desc }}</p>
            <a (click)="showDialog(prodItem.hcont_id, prodItem.hcont_link)"> Click Here</a>
        </p-accordionTab>
    </p-accordion>

    <p-dialog header="Header" [(visible)]="visible" [style]="{width: '50vw'}">
             
      
        <video autoplay #media id="singleVideo" preload="auto" 
        [src]="videoLinkSrc" controls [style]="{width: '50vw'}">  
        </video>
      
      <!-- Button trigger modal -->
      </p-dialog>
</div>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>
