import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { UserRoles } from 'src/app/model';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UserRolesService {

  public pageName = 'User Role';
  public userRolesList = new Subject<Array<UserRoles>>();

  constructor(
    private config: AppConfigService,
    private http: HttpClient,
    private msgService: MsgService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.userRolesApi[apiOption];
  }

  public getDataRecords() {
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined }).subscribe((response: any) => {
      if (response && response && response.result) {
        const UserRolesList: Array<UserRoles> = [];
        response.result.userRoles.forEach(element => {
          element.status = element.status == '1' ? true : false;
          UserRolesList.push(element);
        });
        this.userRolesList.next(UserRolesList);
      }
    },
      err => {
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
      });
  }

  public addData(userRole: UserRoles) {
    //const fData = this.fillData(userRole);
    //return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
    return this.fillData(this.getUrl(ApiActions.Create), userRole);
  }

  public updateData(userRole: UserRoles) {
    //const fData = this.fillData(userRole);
    //return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
    return this.fillData(this.getUrl(ApiActions.Update), userRole);
  }

  private fillData(action: string, objData: UserRoles) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('userRoleId', objData.userRoleId + '');
    fData.append('userroleCode', objData.userRole);
    fData.append('userroleManager', objData.userRoleMngr);
    fData.append('menuManageIds', objData.userRoleMenuIds);
    fData.append('status', objData.status ? '1' : '0');
    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.userRoleDetails.status });
        responseMsg.next(response.result.userRoleDetails.status);
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

  public updateStatus(objData: UserRoles) {
    const fData = new FormData();
    const responseMsg = new Subject<string>();
    fData.append('userRoleId', objData.userRoleId + '');
    fData.append('status', objData.status ? '1' : '0');
    //return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
    this.http.post(this.getUrl(ApiActions.UpdateStatus), fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.userRoleDetails.status });
        responseMsg.next(response.result.userRoleDetails.status);
      } else {
        this.msgService.addErrorMsg({ summary: this.pageName, detail: response.Error });
      }
    },
      err => {
        responseMsg.next(err);
      });
    return responseMsg;
  }

  public deleteData(id: number) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('userRoleId', id + '');
    // return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);

    this.http.post(this.getUrl(ApiActions.Delete), fData, { headers: undefined }).subscribe((response: any) => {
      if (response.result.userDetails) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.userDetails.status });
        responseMsg.next(response.result.userDetails.status);
      } else {
        this.msgService.addErrorMsg({ summary: this.pageName, detail: response.Error });
      }
    },
      err => {
        responseMsg.next(err);
      });
    return responseMsg;

  }

  public getRolesData() {
    const url = this.config.ipAddress + this.config.userRolesApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        const userRolesList: Array<UserRoles> = [];
        response.result.userRoles.forEach(element => {
          element.userroleStatus = element.userroleStatus ? true : false;
          userRolesList.push(element);
        });
        this.userRolesList.next(userRolesList);
      }
    },
      err => {
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
      });
  }

}
