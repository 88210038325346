import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject } from 'rxjs';
import { DispatchQuantity ,ProductUOM} from 'src/app/model';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { ApiActions, UtilService } from '../common/util.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class DispatchQuantityService {

  public pageName = 'Dispatch Quantity';
  public dataList = new Subject<Array<DispatchQuantity>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  public getDataRecords(fromDate: Date, toDate: Date) {
    let resultList = new Array<DispatchQuantity>();
    const fData = new FormData();
    fData.append('fromDate', this.util.getDate(fromDate));
    fData.append('toDate', this.util.getDate(toDate));
    this.http.post(this.getUrl(ApiActions.Get), fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          resultList = response.result.DispatchedQuantityOrders.map(e => {
            // tslint:disable-next-line: no-string-literal
            e['dispatchedQty'] = 0;
            // tslint:disable-next-line: no-string-literal
            e['status'] = 1;
            return e;
          });
          this.dataList.next(resultList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
  }
  public getUOMCodes() {
    const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
    const dataArray: Array<ProductUOM> = [];
    const data = new BehaviorSubject<Array<ProductUOM>>([]);

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.UnitsOfMeasurement.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return data;
  }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.dispatchedQuantityApi[apiOption];
  }

  public updateData(objList: Array<DispatchQuantity>) {
    return this.Save(this.getUrl(ApiActions.Update), objList, this.util.getSaveStatus());
  }

  public saveDraft(objList: Array<DispatchQuantity>) {
    return this.Save(this.getUrl(ApiActions.DraftSave), objList, this.util.getDraftStatus());
  }

  private Save(action: string, objList: Array<DispatchQuantity>, statusVal: number) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();

    const data = objList.map(obj => {
      return {
        custId: obj.custId,
        customerOrderId: obj.customerOrderId,
        customerPurchaseOrder: obj.customerPurchaseOrder,
        prodId: obj.prodId,
        orderQuantity: obj.orderQuantity,
        dispatchQuantity: obj.dispatchQuantity,
        prodSpecs: obj.prodSpecs,
        UOM: obj.UOM_ID,
        BatchNumber: obj.BatchNumber,
        FinishedQty: obj.FinishedQty,
        status: statusVal
      };
    });

    // order_code, dispatch_uom, dispatch_date
    fData.append('data', JSON.stringify(data));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

}
