import { Injectable } from "@angular/core";
import { ApiActions, UtilService } from "../common/util.service";
import { BehaviorSubject, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "../common/app-config.service";
import { MsgService } from "../common/msg.service";
import { Purchasesquatationsagainstenquiry,ProductUOM } from "src/app/model";
import { Router } from "@angular/router";
@Injectable({
    providedIn: "root",
})
export class PurchasesquatationsagainstenquiryService {
    public pageName = "Quotation vs Enquiry";
    public dataList = new Subject<Array<Purchasesquatationsagainstenquiry>>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return (
            this.config.ipAddress +
            this.config.PurchasesquatationsagainstenquiryApi[apiOption]
        );
    }
    public getCUrrancyList(): Subject<Array<any>> {
        const url = this.config.ipAddress + this.config.currencyserviceApi[ApiActions.Get];
        const countrylist: Array<any> = [];
        const result = new Subject<Array<any>>();
        this.http.post(url, null, { headers: undefined }).subscribe(
            (response: any) => {
                if (response && response.result) {
                    response.result.Currency.map((element) => {
                        // element.status = Number(element.recStatus) === 1 ? true : false;
                        countrylist.push(element);
                    });
                    result.next(countrylist);
                }
            },
            (err) => {
                this.msgService.addErrorMsg({
                    summary: "Login",
                    detail: " Something Went Wrong Please Try Again",
                });
                setTimeout(() => {
                    localStorage.clear();
                    this.router.navigate(["/account/login"]);
                }, 1000);
            }
        );
        return result;
    }
    public getUOMCodes() {
        const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
        const data = new Subject<Array<ProductUOM>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.UnitsOfMeasurement;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });  
                });
        return data;
    }
    public getDataRecords() {
        let resultList = new Array<Purchasesquatationsagainstenquiry>();
        this.http
            .post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe(
                (response: any) => {
                    if (
                        response &&
                        response.result &&
                        response.result.PurchasesquatationList
                    ) {
                        resultList = response.result.PurchasesquatationList.map((e) => {
                            e.QuotDate = new Date(e.QuotDate);

                            return e;
                        });
                        this.dataList.next(resultList);
                    }
                },
                (err) => {
                    this.msgService.addErrorMsg({
                        summary: "Login",
                        detail: " Something Went Wrong Please Try Again",
                    });
                    setTimeout(() => {
                        localStorage.clear();
                        this.router.navigate(["/account/login"]);
                    }, 1000);
                }
            );
    }

    public updateData(objData: Purchasesquatationsagainstenquiry) {
        console.log(objData);
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public savedraft(objData: Purchasesquatationsagainstenquiry) {
        console.log(objData);
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Create), objData);
    }

    private Save(action: string, objData: Purchasesquatationsagainstenquiry) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append("purquot_id", objData.purquot_id + "");
        fData.append("purenq_id", objData.purenq_id + "");
        fData.append("supp_id", objData.supp_id + "");
        fData.append('QuotCode', objData.QuotCode + '');
        fData.append('QuotDate', this.util.getDate(objData.QuotDate));

        const items = objData.items.map((e) => {
            return {
                purquotdtl_id: e.purquotdtl_id,
                prodId: e.item_id,
                prodSpecs: e.ItemSpecs,
                orderUom: e.uom_id,
                QuotQty: e.QuotQty,
                QuotPrice: e.QuotPrice,
                currency_id: e.currency_id
            };
        });

        fData.append("items", JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe(
            (response: any) => {
                if (response.status_code == 201){
                    this.msgService.addWarnMsg({ summary: this.pageName, detail: response.status });
                    responseMsg.next('fail');
                  }
                  else if (response.result.status_code == 200) {
                    this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                    responseMsg.next('');
                  }
            },
            (err) => {
                responseMsg.next(err);
            }
        );

        return responseMsg;
    }
}
