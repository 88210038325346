import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import { UnitMaster, ProductMaster } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UnitMasterService {

  public pageName = 'Unit Master';
  public dataList = new Subject<Array<UnitMaster>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.unitMasterApi[apiOption];
  }

  public getProductMasterRecords() {
    const resultList = new Subject<Array<ProductMaster>>();
    const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
          const list = response.result.productMasterList.filter(e => e.status > this.util.approvedStatus);
          resultList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
    return resultList;
  }

  public getDataRecords() {
    const dataArray: Array<UnitMaster> = [];
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.unitMasterList) {
          response.result.unitMasterList.map(element => {
            dataArray.push(element);
          });
        }
        this.dataList.next(dataArray);
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: ' Something Went Wrong Please Try Again' });   
        });
  }

  public updateData(objData: UnitMaster) {
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: UnitMaster) {
    objData.status = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: UnitMaster) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('status', objData.status + '');
    fData.append('unitId', objData.unitId + '');
    fData.append('unitName', objData.unitName);
    fData.append('unitDesc', objData.unitDesc);
    fData.append('unitAddress', objData.unitAddress);
    fData.append('unitLicenseNo', objData.unitLicenseNo + '');
    fData.append('manufacturedProds', objData.manufacturedProds);
    fData.append('noOfLines', objData.noOfLines + '');
    fData.append('gstinNo', objData.gstinNo);

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

}
